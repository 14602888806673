import {
  pantalla_baja,
  pantalla1,
  pantalla1_1,
  pantalla1_2,
  pantalla2,
  pantalla2_1,
  pantalla3,
  pantalla4,
  pantalla5,
  pantalla6,
  pantalla6_1,
  pantalla7,
  pantalla_link_error,
  pantalla_error,
} from './layoutsForms'

const steps = {
  numSteps: 7,
  steps: {
    '0_1': {
      step: '0_1',
      nextStep: 'undefined',
      errorNextStep: 'undefined',
      previusStep: '0_1',
      screen: pantalla_baja,
    },
    0: {
      step: '0',
      nextStep: 'undefined',
      errorNextStep: 'undefined',
      previusStep: '0',
      screen: pantalla_link_error,
    },
    1: {
      step: '1',
      nextStep: '2',
      errorNextStep: '1_1',
      previusStep: '1',
      screen: pantalla1,
    },
    '1_1': {
      step: '1_1',
      nextStep: '2',
      errorNextStep: '1_2',
      previusStep: '1_1',
      screen: pantalla1_1,
    },
    '1_2': {
      step: '1_2',
      nextStep: '1',
      errorNextStep: '1_2',
      previusStep: '1_2',
      screen: pantalla1_2,
    },
    2: {
      step: '2',
      nextStep: '3',
      previusStep: '1',
      screen: pantalla2,
    },
    3: {
      step: '3',
      nextStep: '4',
      previusStep: '2',
      screen: pantalla2_1,
    },
    4: {
      step: '4',
      nextStep: '5',
      previusStep: '3',
      screen: pantalla3,
    },
    5: {
      step: '5',
      nextStep: '6',
      previusStep: '4',
      screen: pantalla4,
    },
    6: {
      step: '6',
      nextStep: '7',
      previusStep: '5',
      screen: pantalla5,
    },
    7: {
      step: '7',
      nextStep: '8',
      previusStep: '6',
      errorNextStep: '7_1',
      screen: pantalla6,
    },
    '7_1': {
      step: '7_1',
      nextStep: '7_1',
      previusStep: '7_1',
      errorNextStep: '7_1',
      screen: pantalla6_1,
    },
    8: {
      step: '8',
      nextStep: '8',
      previusStep: '8',
      screen: pantalla7,
    },
  },
}
export default steps
