import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import ProgressMobileStepper from './progressMobileStepper'
import Select from '@material-ui/core/Select'
import esLocale from 'date-fns/locale/es'
import MapContainer from './componentsFormulay/geoPosition'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider, TimePicker, DatePicker } from 'material-ui-pickers'
import steps from '../steps'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import CircularProgress from '@material-ui/core/CircularProgress'
import AlertDialog from './componentsFormulay/dialogGeoError'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    width: '100%',
  },
  checkboxContainer: {
    marginLeft: 10,
  },

  formControl: {
    margin: 0,
    padding: 0,
  },
  selector: {
    maxWidth: 1200,
  },
  typography: {
    marginTop: '3%',
    fontWeight: 'bold',
    color: '#6E6E6E',
  },
  contarinerTypography: {
    maxWidth: '97%',
  },
  button: {
    borderRadius: 10,
  },
  disabled: {
    color: 'black',
    '&:disabled': {
      color: '#716F6F',
    },
  },
  disabledArea: {
    color: 'black',
    '&:disabled': {
      color: 'black',
    },
  },
}))

const isToday = (aux) => {
  const today = new Date()
  const someDate = new Date(aux)
  return new Date(someDate).getDate() == today.getDate() && someDate.getMonth() == today.getMonth() && someDate.getFullYear() == today.getFullYear()
}

function RenderFormulary(props) {
  const classes = useStyles()
  const { t } = useTranslation()
  const [numSteps] = useState(steps.numSteps)
  const objFormulary = steps.steps[props.step].screen
  const [sendInprocess, setSendInprocess] = useState(false)
  const [hideComponets, setHideComponets] = useState({})
  const [values, setValues] = useState({ company: `${props.clientId}`, idCall: parseInt(`${props.idLlamada}`) })
  const [finalValues, setFinalValues] = useState({ company: `${props.clientId}`, idCall: parseInt(`${props.idLlamada}`) })
  const [infoValues, setInfoValues] = useState({})
  const [errorsRequired, setErrorsRequired] = useState({})
  const [errorsValidate, setErrorsValidate] = useState({})
  const [openGeoAlert, setOpenGeoAlert] = React.useState(false)
  const [previusData, setPreviusData] = useState(undefined)
  const [step, setStep] = useState(undefined)

  function getItem(id) {
    let result = undefined

    objFormulary.values.map((e) => {
      if (e.id == id) result = e
    })
    return result
  }
  useEffect(() => {
    if (props.previusData && !previusData) {
      if (props.previusData.isCanceled) {
        console.log('cancelada')
        props.setStep('0_1')
      } else if (props.previusData.record) {
        infoAlta()
        props.setStep('8')
      }else if (props.previusData.idScreen === '1_2') {
        props.setStep('1')
      }

      setPreviusData(props.previusData)
      setValues(props.previusData)
      setFinalValues(props.previusData)
      validezasegurado(props.previusData)
    }
  })
  useEffect(() => {
    if (props.previusData && previusData && (!step || step != props.step)) {
      setStep(props.step)
      setPreviusData(props.previusData)
      let objectPreviusData = {}

      if (finalValues.tireReplacement == true) {
        objectPreviusData.moreWheels = 'true'
      }
      if (finalValues.tireReplacement == false) {
        objectPreviusData.moreWheels = 'false'
      }

      if (finalValues.address && finalValues.address != '' && (finalValues.geoPosition == undefined || finalValues.geoPosition == '')) {
        objectPreviusData.geoPosition = finalValues.address
      }

      if (finalValues.roadType) {
        let item = getItem('roadType')

        if (item && finalValues.roadType == 'U') InputChangeCheckBox(item.id, true, item.hideChildrens, item.showChildrens, item.group, item.label)
      }
      if (finalValues.noBattery) {
        let item = getItem('noBattery')

        if (item) InputChangeCheckBox(item.id, true, item.hideChildrens, item.showChildrens, item.group, item.label)
      } else if (finalValues.noFuel) {
        let item = getItem('noFuel')

        if (item) InputChangeCheckBox(item.id, true, item.hideChildrens, item.showChildrens, item.group, item.label)
      } else if (finalValues.noStart) {
        let item = getItem('noStart')

        if (item) InputChangeCheckBox(item.id, true, item.hideChildrens, item.showChildrens, item.group, item.label)
      } else if (finalValues.keysInside) {
        let item = getItem('keysInside')

        if (item) InputChangeCheckBox(item.id, true, item.hideChildrens, item.showChildrens, item.group, item.label)
      } else if (finalValues.otherFault) {
        let item = getItem('otherFault')

        if (item) InputChangeCheckBox(item.id, true, item.hideChildrens, item.showChildrens, item.group, item.label)
      } else if (finalValues.flatTiresCount > 0) {
        let item = getItem('flatTires')

        if (item) InputChangeCheckBox(item.id, true, item.hideChildrens, item.showChildrens, item.group, item.label)
      }
      if (!finalValues.immediate) {
        let item = getItem('immediate')
        if (finalValues.deferred) {
          objectPreviusData.dia = finalValues.deferred
          objectPreviusData.hora = finalValues.deferred.split('T')[1].split(':00Z')[0]
        }
        if (item) InputChangeCheckBox(item.id, false, item.hideChildrens, item.showChildrens, item.group, item.label)
      }
      setValues({ ...values, ...objectPreviusData })
      setFinalValues({ ...finalValues, ...objectPreviusData })
    }
  })
  const expired = props.expired
  if (props.step === '1') {
    props.setFirstStep(true)
  } else {
    props.setFirstStep(false)
  }
  useEffect(() => {
    if (expired) props.setStep('0')
  }, [expired])
  useEffect(() => {
    if (props.cancel) {
      setTimeout(function () {
        window.location.href = window.location.href
      }, 200)

      setValues({ company: `${props.clientId}`, idCall: parseInt(`${props.idLlamada}`) })
      setFinalValues({ company: `${props.clientId}`, idCall: parseInt(`${props.idLlamada}`) })

      props.setCanceled(false)
    }
  })

  //validar los valores cuando se pulsa send
  function validateValues() {
    for (var val in values) {
      if (isNaN(values[val])) {
        var valor = values[val]
        switch (val) {
          case 'name':
            values.name = valor.trim()
            break
          case 'surname':
            values.surname = valor.trim()
            break
          case 'carLicense':
            values.carLicense = valor.trim()
            break
          case 'dni':
            values.dni = valor.trim()
            break
        }
      }
    }

    let succes = true
    let auxErrorsRequired = {}
    let auxErrorsValidate = {}
    var numAverias = 0
    objFormulary.values.map((e) => {
      if ((!e.notRender || hideComponets[e.id] == false) && (hideComponets[e.id] == false || hideComponets[e.id] == undefined)) {
        let value = values[e.id]
        auxErrorsRequired[e.id] = false
        auxErrorsValidate[e.id] = false
        // comprbar si hay alguna avería seleccionada
        if (e.groupIds != undefined && !value) {
          numAverias = numAverias + 1
          if (e.groupIds.length == numAverias) {
            alert(t('rquired_checkbox'))
            succes = false
          }
        }
        // comprbar si el valor es requerido antes de mandarlo
        if ((value === undefined || value === '') && e.required) {
          auxErrorsRequired[e.id] = true
          succes = false
        }
        // comprbar si la suma de los selector es igual
        if (e.optionsId) {
          if (values[e.optionsId[0]] !== values[e.optionsId[1]] + values[e.id]) {
            auxErrorsValidate[e.id] = true
            succes = false
          }
        }
        // comprobar las expresiones regular de los campos
        if (value && !auxErrorsRequired[e.id]) {
          if (e.regExr && e.regExr !== '') {
            var re = new RegExp(e.regExr)
            if (!re.test(value)) {
              auxErrorsValidate[e.id] = true
              succes = false
            }
          }
        }
        //Validar que la hora sea +2h en el caso de que el dia coincida en el actual
        if (value && e.timePicker && values[e.id]) {
          const dateAux = values[e.id].split(':')
          const time = new moment({ hour: dateAux[0], minute: dateAux[1] })
          if (values['dia'] && isToday(values['dia'])) {
            if (time.isBefore(moment().add(2, 'hours'))) {
              auxErrorsValidate[e.id] = true
              succes = false
            }
          }
        }
        if (e.id == 'geo_ubicacion') {
          if (values['geoPosition'] == undefined || values['geoPosition'].trim() == '' || values['latitude'] == undefined || values['longitude'] == undefined) {
            if (values['address'] != undefined || values['address'].trim() != '') {
              setValues({ ...values, geoPosition: values.address })
              setFinalValues({ ...values, geoPosition: values.address })
            } else {
              auxErrorsValidate[e.id] = true
              setOpenGeoAlert(true)
              succes = false
            }
          }
        }
        return value
      }
    })
    setErrorsRequired(auxErrorsRequired)
    setErrorsValidate(auxErrorsValidate)
    return succes
  }

  function send() {
    setSendInprocess(true)
    let endpoint = '/api/register/trazaincidencia'
    let data = values

    if (numSteps == props.step && !props.lastStep) {
      props.setLastStep(true)
      data = finalValues
      endpoint = '/api/register/altaincidencia'
    }
    if (objFormulary.endpoint) {
      data = {
        idLlamada: parseInt(values.idCall),
        entidad: values.company,
        cif: values.dni,
        matricula: values.carLicense,
        tomador: values.surname + ' ,' + values.name,
      }
    }

    if (data.moreWheels == 'true') {
      data.tireReplacement = true
    }
    if (data.moreWheels == 'false') {
      data.tireReplacement = false
    }
    if (data.geoPosition) {
      data.address = data.geoPosition
    }
    if (data.geoPosition) {
      data.address = data.geoPosition
    }
    data.idScreen = props.step

    if (validateValues()) {
      if (objFormulary.alertFinal) alert(t(objFormulary.alertFinal))
      let url = endpoint
      if (objFormulary.endpoint) url = '/api/register/' + objFormulary.endpoint
      fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}${url}`, {
        mode: 'cors',
        method: 'POST',
        body: JSON.stringify({
          ...data,
        }),
        headers: {
          'Content-Type': 'application/json',
          Accept: '*/*',
        },
      })
        .then((res) => res.json())
        .catch((error) => {
          setSendInprocess(false)
          console.error('Error:', error)
        })
        .then((response) => {
          setSendInprocess(false)
          if (endpoint == '/api/register/altaincidencia') {
            console.log(response)
            setValues({ ...values, record: response.expediente, collaborator: response.colaborador, altaResult: response.descripcion })
            setFinalValues({ ...finalValues, record: response.expediente, collaborator: response.colaborador, altaResult: response.descripcion })
            setInfoValues({ record: response.expediente, collaborator: response.colaborador, altaResult: response.descripcion })
          } else if (endpoint == '/api/register/validezasegurado' || objFormulary.endpoint == 'validezasegurado') {
            //redirigir a ultima pantalla registrada
            if (response.idScreen != null) props.setStep(steps.steps[response.idScreen])
            if (response.tomador != '') {
              setValues({ ...values, policyCode: response.codigoPoliza, tomador: response.tomador })
              setFinalValues({ ...finalValues, policyCode: response.codigoPoliza, tomador: response.tomador })
            }
          } else if (objFormulary.endpoint) {
            setValues({ ...values, policyCode: response.codigoPoliza, tomador: response.tomador })
            setFinalValues({ ...finalValues, policyCode: response.codigoPoliza, tomador: response.tomador })
          } else setValues({ ...finalValues })

          if (
            (objFormulary.endpoint &&
              (response.error != undefined ||
                response.codigo == '101' ||
                response.codigo == '102' ||
                response.codigo == '180' ||
                response.codigo == '199' ||
                response.codigo == '417' ||
                response.codigo == '418' ||
                response.codigo == '103' ||
                response.codigo == '412' ||
                response.codigo == '410')) ||
            response.codigo == '499'
          ) {
            props.setStep(steps.steps[props.step].errorNextStep)
            setSendInprocess(false)
            props.setLastStep(false)
            if (steps.steps[steps.steps[props.step].errorNextStep].screen.alert) {
              alert(t(steps.steps[steps.steps[props.step].errorNextStep].screen.alert) || response.response)
            }
          } else props.setStep(steps.steps[props.step].nextStep)
        })
    } else {
      setSendInprocess(false)
      return
    }
  }
  function infoAlta() {
    fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/register/infoincidencia?clientId=${values.company}&callId=${values.idCall}`, {
      mode: 'cors',
      method: 'GET',
      headers: {
        Accept: '*/*',
      },
    })
      .then((res) => res.json())
      .then((response) => {
        let desc = 'grua_ok'
        setInfoValues({ record: response.expediente, collaborator: response.colaborador, altaResult: desc })
      })
      .catch((error) => {})
  }

  function validezasegurado(previusData) {
    let data = {
      idLlamada: parseInt(values.idCall),
      entidad: values.company,
      cif: previusData.dni,
      matricula: previusData.carLicense,
      tomador: previusData.surname + ' ,' + previusData.name,
    }
    fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/register/validezasegurado`, {
      mode: 'cors',
      method: 'POST',
      body: JSON.stringify({
        ...data,
      }),
      headers: {
        'Content-Type': 'application/json',
        Accept: '*/*',
      },
    })
      .then((res) => {
        if (res.status == 200) return res.json()
        else return false
      })
      .then((response) => {
        if (response) {
          setValues({ ...previusData, tomador: response.tomador })
          setFinalValues({ ...previusData, tomador: response.tomador })
        }
      })
  }

  const handleInputChange = (e, inputID) => {
    let { id, value } = e.target
    if (inputID) id = inputID
    setErrorsRequired({ ...errorsRequired, [id]: false })
    setErrorsValidate({ ...errorsValidate, [id]: false })
    setValues({ ...values, [id]: value })
    setFinalValues({ ...finalValues, [id]: value })
  }

  const handleInputChangeSelector = (e, inputID, showChildrens, showChildrensIfValue) => {
    const arrayShowChildrens = showChildrens || []
    let auxHideComponets = {}
    let hidenaux = {}
    let { id, value } = e.target
    if (inputID) id = inputID
    setErrorsRequired({ ...errorsRequired, [id]: false })
    setErrorsValidate({ ...errorsValidate, [id]: false })
    setValues({ ...values, [id]: value })
    setFinalValues({ ...finalValues, [id]: value })

    if (arrayShowChildrens.length > 0 && value === showChildrensIfValue) {
      arrayShowChildrens.map((element) => {
        hidenaux = { ...auxHideComponets, ...hidenaux, [element]: false }
      })
      setHideComponets(hidenaux)
    } else if (arrayShowChildrens.length > 0 && value !== showChildrensIfValue) {
      arrayShowChildrens.map((element) => {
        hidenaux = { ...auxHideComponets, ...hidenaux, [element]: true }
      })
      setHideComponets(hidenaux)
    }
  }

  const handleInputChangePickers = (value, inputID, type) => {
    if (type === 2 && !values['hora']) {
      setValues({ ...values, [inputID]: moment(value, 'MMM-DD-YYYY').format() })
      setFinalValues({ ...finalValues, [inputID]: moment(value, 'MMM-DD-YYYY').format() })
    }
    if (type === 2 && values['hora']) {
      let deferred = moment(value, 'MMM-DD-YYYY').format().split('T')[0] + 'T' + values['hora'] + ':00.000Z'
      setValues({ ...values, [inputID]: moment(value, 'MMM-DD-YYYY').format(), deferred })
      setFinalValues({ ...finalValues, [inputID]: moment(value, 'MMM-DD-YYYY').format(), deferred })
    }

    if (type === 1 && values['dia']) {
      let deferred = values['dia'].split('T')[0] + 'T' + value + ':00.000Z'
      setValues({ ...values, [inputID]: value, deferred })
      setFinalValues({ ...finalValues, [inputID]: value, deferred })
    }
    if (type === 1 && !values['dia']) {
      setValues({ ...values, [inputID]: value })
      setFinalValues({ ...finalValues, [inputID]: value })
    }
  }

  function handleInputChangeCheckBox(e, hideChildrens, showChildrens, group, label, groupIds, code) {
    const arrayHideChildrens = hideChildrens || []
    const arrayShowChildrens = showChildrens || []
    const arrayGroupIds = groupIds || []
    let { name, checked } = e.target
    let hidenaux = {}
    let valuesaux = {}
    let valuesFinalaux = {}
    let auxHideComponets = {}
    if (values[name] && !checked) return
    arrayHideChildrens.map((element) => {
      hidenaux = { ...auxHideComponets, ...hidenaux, [element]: checked }
    })
    arrayShowChildrens.map((element) => {
      hidenaux = { ...auxHideComponets, ...hidenaux, [element]: !checked }
    })

    arrayGroupIds.map((element) => {
      if (name != element) {
        valuesFinalaux = { ...finalValues, ...valuesaux, [element]: false }
        valuesaux = { ...values, ...valuesaux, [element]: false }
      } else {
        valuesFinalaux = { ...finalValues, ...valuesaux, [element]: true }
        valuesaux = { ...values, ...valuesaux, [element]: true }
      }
    })
    if (arrayGroupIds.length > 0) {
      if (group) {
        setValues({ ...valuesaux, [group]: code })
        setFinalValues({ ...valuesFinalaux, [group]: code })
      } else setValues({ ...valuesaux })
    } else setValues({ ...values, [name]: checked })
    if (!group) {
      setFinalValues({ ...finalValues, [name]: checked })
    }
    setHideComponets(hidenaux)
  }

  function handleInputChangeCheckBoxSelector(e, hideChildrens, showChildrens, group, label, groupIds) {
    const arrayHideChildrens = hideChildrens || []
    const arrayShowChildrens = showChildrens || []
    let { name, value } = e.target
    let checked = false
    if (value == t(`yes`)) checked = true
    let hidenaux = {}
    if (arrayHideChildrens.length > 0) {
      arrayHideChildrens.map((element) => {
        hidenaux = { ...hideComponets, ...hidenaux, [element]: checked }
      })
      arrayShowChildrens.map((element) => {
        hidenaux = { ...hideComponets, ...hidenaux, [element]: !checked }
      })

      setHideComponets(hidenaux)
    }
    setFinalValues({ ...finalValues, [name]: checked })
    setValues({ ...values, [name]: checked })
  }

  function InputChangeCheckBox(name, checked, hideChildrens, showChildrens, group, label) {
    const arrayHideChildrens = hideChildrens || []
    const arrayShowChildrens = showChildrens || []
    let hidenaux = {}

    arrayHideChildrens.map((element) => {
      hidenaux = { ...hideComponets, ...hidenaux, [element]: checked }
    })
    arrayShowChildrens.map((element) => {
      hidenaux = { ...hideComponets, ...hidenaux, [element]: !checked }
    })
    setValues({ ...values, [name]: checked })
    if (!group) {
      setFinalValues({ ...finalValues, [name]: checked })
    } else if (group) {
      const labelt = t(label)

      setFinalValues({ ...finalValues, [name]: checked, [group]: labelt })
    }

    setHideComponets(hidenaux)
  }

  function handleGeoPosition(geovalue, latitude, longitude) {
    setValues({ ...values, geoPosition: geovalue, latitude, longitude })
    setFinalValues({ ...finalValues, geoPosition: geovalue, latitude, longitude })
  }

  return objFormulary.values.map((item) => {
    if ((!item.notRender && !hideComponets[item.id]) || (hideComponets[item.id] != undefined && !hideComponets[item.id])) {
      //Recuperación ClientId
      let clientRetrive = ''
      const urlParamsClient = new URLSearchParams(window.location.search)
      const auxClient = urlParamsClient.get('clientId')
      if (auxClient != null) {
        switch (auxClient) {
          case '00000025':
            clientRetrive = 'tlf_reale'
            break
          case '00000016':
            clientRetrive = 'tlf_Asitur'
            break
          case '00000002':
            clientRetrive = 'tlf_catalana'
            break
          case '00000001':
            clientRetrive = 'tlf_SegurosBilbao'
            break
          case '00000020':
            clientRetrive = 'tlf_PlusUltra'
            break
          case '00000007':
            clientRetrive = 'tlf_UnionAlcoyana'
            break
          case '00000010':
            clientRetrive = 'tlf_MGS'
            break
          case '00000011':
            clientRetrive = 'tlf_Helvetia'
            break
          case '00000006':
            clientRetrive = 'tlf_GES'
            break
        }
      } else {
        const fullUrlClient = window.location.href
        const paramsClient = fullUrlClient.split('/')
        let clientIdLetClient = ''
        if (paramsClient.length >= 6) {
          clientIdLetClient = paramsClient[4]
        }
        var auxPad0Client = clientIdLetClient
        auxPad0Client = auxPad0Client.length < 8 ? auxPad0Client.padStart(8, 0) : auxPad0Client
        switch (auxPad0Client) {
          case '00000025':
            clientRetrive = 'tlf_reale'
            break
          case '00000016':
            clientRetrive = 'tlf_Asitur'
            break
          case '00000002':
            clientRetrive = 'tlf_catalana'
            break
          case '00000001':
            clientRetrive = 'tlf_SegurosBilbao'
            break
          case '00000020':
            clientRetrive = 'tlf_PlusUltra'
            break
          case '00000007':
            clientRetrive = 'tlf_UnionAlcoyana'
            break
          case '00000010':
            clientRetrive = 'tlf_MGS'
            break
          case '00000011':
            clientRetrive = 'tlf_Helvetia'
            break
          case '00000006':
            clientRetrive = 'tlf_GES'
            break
        }
      }

      //Fin Recuperación ClientId

      let helperText = ''
      let defaultValue = ''
      let value = ''

      if (errorsValidate[item.id] === undefined) errorsValidate[item.id] = false
      if (errorsValidate[item.id] === true && item.helperText) helperText = t(item.helperText.toString())
      if (errorsRequired[item.id] === undefined) errorsRequired[item.id] = false
      if (errorsRequired[item.id] === true) helperText = t('required')
      let error = errorsValidate[item.id] || errorsRequired[item.id]

      switch (item.typeField) {
        case 'TextInput':
          if (values[item.id]) {
            value = values[item.id]
          } else {
            value = ''
          }
          if (item.defaultValue) {
            defaultValue = item.defaultValue
            value = item.defaultValue
            if (!values[item.id]) {
              setValues({ ...values, [item.id]: value })
              setFinalValues({ ...finalValues, [item.id]: value })
            }
          } else defaultValue = ''
          return (
            <Grid key={item.id} item xs={12} sm={item.size}>
              <TextField
                color="primary"
                inputProps={{
                  maxLength: item.maxLength,
                  minLength: 3,
                }}
                InputProps={{
                  classes: {
                    root: classes.inputRoot,
                    disabled: classes.disabled,
                  },
                }}
                disabled={item.disabled}
                variant="outlined"
                error={error}
                helperText={helperText}
                required={item.required}
                id={`${item.id}`}
                name={`${item.label}`}
                label={t(`${item.label}`)}
                fullWidth
                value={item.disabled && !item.no_traslation ? t(value) : value}
                onChange={handleInputChange}
              />
            </Grid>
          )
        case 'Checkbox':
          console.log([item.id], finalValues[item.id], values[item.id])
          if (item.defaultValue && values[item.id] == undefined) {
            InputChangeCheckBox(item.id, true, item.hideChildrens, item.showChildrens, item.group, item.label)
          } else if (values[item.id] == undefined) {
            setValues({ ...values, [item.id]: false })
          }
          if (values[item.id] == undefined) return <Grid></Grid>

          return item.selector ? (
            <Grid key={item.id} item xs={12} sm={item.size}>
              <FormControl variant="outlined" className={classes.formControl} fullWidth>
                {item.label == 'immediate' ? null : <InputLabel>{t(`${item.label}`)}</InputLabel>}

                <Select
                  color="primary"
                  className={classes.selector}
                  error={error}
                  disabled={item.disabled}
                  labelId={item.id}
                  name={`${item.id}`}
                  value={values[item.id] ? t(`yes`) : t(`no`)}
                  id={`${item.id}`}
                  label={item.label == 'immediate' ? null : t(`${item.label}`)}
                  onChange={(e) => handleInputChangeCheckBoxSelector(e, item.hideChildrens, item.showChildrens, item.group, item.label, item.groupIds)}
                  //label={t(`${item.label}`)}
                  fullWidth
                >
                  <MenuItem key={`yes`} id={`yes`} value={t(`yes`)}>
                    {item.valuesSelector ? t(item.valuesSelector[0]) : t(`yes`)}
                  </MenuItem>
                  <MenuItem key={`no`} id={`no`} value={t(`no`)}>
                    {item.valuesSelector ? t(item.valuesSelector[1]) : t(`no`)}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          ) : (
            <Grid key={item.id} item xs={12} sm={item.size}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    id={`${item.id}`}
                    color="secondary"
                    name={`${item.id}`}
                    disabled={item.disabled}
                    value={values[item.id]}
                    onChange={(e) => handleInputChangeCheckBox(e, item.hideChildrens, item.showChildrens, item.group, item.label, item.groupIds, item.code)}
                    checked={values[item.id]}
                  />
                }
                label={t(`${item.label}`)}
              />
            </Grid>
          )
        case 'TextArea':
          if (item.legalText) {
            const urlParams = new URLSearchParams(window.location.search)
            const aux = urlParams.get('clientId')

            if (aux != null) {
              switch (urlParams.get('clientId')) {
                case '00000025':
                  defaultValue = t(item.defaultValueReale)
                  break
                case '00000016':
                  defaultValue = t(item.defaultValueAsitur)
                  break
                case '00000002':
                  defaultValue = t(item.defaultValueCatalana)
                  break
                case '00000001':
                  defaultValue = t(item.defaultValueSegurosBilbao)
                  break
                case '00000020':
                  defaultValue = t(item.defaultValuePlusUltra)
                  break
                case '00000007':
                  defaultValue = t(item.dafaultValueUnionAlcoyana)
                  break
                case '00000010':
                  defaultValue = t(item.defaultValueMGS)
                  break
                case '00000011':
                  defaultValue = t(item.defaultValueHelvetia)
                  break
                case '00000006':
                  defaultValue = t(item.dafaultValueGES)
                  break
              }
            } else {
              const fullUrl = window.location.href
              const params = fullUrl.split('/')
              let clientIdLet = ''
              if (params.length >= 6) {
                clientIdLet = params[4]
              }
              var auxPad0 = clientIdLet
              auxPad0 = auxPad0.length < 8 ? auxPad0.padStart(8, 0) : auxPad0
              switch (auxPad0) {
                case '00000025':
                  defaultValue = t(item.defaultValueReale)
                  break
                case '00000016':
                  defaultValue = t(item.defaultValueAsitur)
                  break
                case '00000002':
                  defaultValue = t(item.defaultValueCatalana)
                  break
                case '00000001':
                  defaultValue = t(item.defaultValueSegurosBilbao)
                  break
                case '00000020':
                  defaultValue = t(item.defaultValuePlusUltra)
                  break
                case '00000007':
                  defaultValue = t(item.dafaultValueUnionAlcoyana)
                  break
                case '00000010':
                  defaultValue = t(item.defaultValueMGS)
                  break
                case '00000011':
                  defaultValue = t(item.defaultValueHelvetia)
                  break
                case '00000006':
                  defaultValue = t(item.dafaultValueGES)
                  break
              }
            }
          } else if (item.defaultValue) defaultValue = t(item.defaultValue)
          let textValue = values[item.id]
          if (defaultValue) {
            textValue = defaultValue
          }
          return (
            <Grid key={item.id} item xs={12} sm={item.size}>
              <TextField
                color="primary"
                InputProps={{
                  classes: {
                    disabled: classes.disabledArea,
                  },
                }}
                inputProps={{
                  maxLength: item.maxLength,
                  minLength: 3,
                }}
                defaultValue={defaultValue}
                helperText={helperText}
                variant="outlined"
                error={error}
                disabled={item.disabled}
                value={textValue}
                placeholder={t(`${item.label}`)}
                multiline
                rowsMax={20}
                id={`${item.id}`}
                fullWidth
                onChange={handleInputChange}
              />
            </Grid>
          )
        case 'Selector':
          if (item.defaultValue != undefined && (values[item.id] === undefined || values[item.id] === '' || values[item.id] === null)) {
            setValues({ ...values, [item.id]: item.defaultValue })
            setFinalValues({ ...finalValues, [item.id]: item.defaultValue })
          }
          console.log(item.id, values[item.id])

          return (
            <Grid key={item.id} item xs={12} sm={item.size}>
              <FormControl variant="outlined" className={classes.formControl} fullWidth>
                <InputLabel>{t(`${item.label}`)}</InputLabel>
                <Select
                  color="primary"
                  className={classes.selector}
                  error={error}
                  labelId={item.id}
                  value={values[item.id] !== undefined ? values[item.id] : ''}
                  id={`${item.id}`}
                  onChange={(e) => handleInputChangeSelector(e, item.id, item.showChildrens, item.showChildrensIfValue)}
                  label={t(`${item.label}`)}
                  fullWidth
                >
                  {item.options.map((e) => {
                    return (
                      <MenuItem key={e.label} id={`${item.id}`} value={e.value}>
                        {isNaN(e.label) ? t(`${e.label}`) : `${e.label}`}
                      </MenuItem>
                    )
                  })}
                </Select>
                <FormHelperText
                  style={{
                    fontSize: 14,
                    color: 'red',
                  }}
                >
                  {helperText}
                </FormHelperText>
              </FormControl>
            </Grid>
          )
        case 'Text':
          const expe = infoValues['record']
          const colab = infoValues['collaborator']
          const altaResult = infoValues['altaResult']

          let text = ''
          let link = undefined

          if (item.label == 'end_text') {
            if (altaResult == 'grua_ok' || altaResult == 'grua_no_ok') {
              text = expe ? t(`${item.label}`) + t('exp_number') + expe : t(`${item.label}`)
              if (altaResult != 'grua_no_ok') {
                text = colab ? text + ', ' + t('attend_text') + ': ' + colab : text
              } else {
                text = text + ' ' + t(`grua_no_ok`)
              }
            } else if (item.label == 'end_text') text = t('alta_no_ok')
          } else if (item.label == 'tft_text') {
            link = <a href={`tel:+34${t(clientRetrive)}`}>{t(clientRetrive)} </a>
            text = t(`${item.label}`) + ': '
          } else {
            text = t(`${item.label}`)
          }

          return (
            <Grid key={item.id} container direction="row" justify="center" alignItems="center">
              <Grid item xs={12} sm={item.size} className={classes.contarinerTypography} style={{ marginBottom: 5 }}>
                <Typography variant={item.variant} gutterBottom className={classes.typography}>
                  {text}
                  {link ? link : ''}
                </Typography>
              </Grid>
            </Grid>
          )
        case 'geoPosition':
          return (
            <Grid key={item.id} item xs={12} sm={item.size} style={{ marginBottom: 20 }}>
              <AlertDialog open={openGeoAlert} setOpen={setOpenGeoAlert} title={t('alert_geo')}></AlertDialog>
              <MapContainer
                handleGeoPosition={handleGeoPosition}
                mapId={item.id}
                value={values['geoPosition']}
                latitude={values['latitude']}
                longitude={values['longitude']}
                geoPosition={values.geoPosition}
                label={t('find_places')}
              />
            </Grid>
          )
        case 'Button':
          return (
            <Grid key={item.id} item xs={12}>
              <Button className={classes.button} onClick={send} variant="contained" color="primary" fullWidth={true} disabled={sendInprocess}>
                {sendInprocess ? <CircularProgress></CircularProgress> : t(`${item.label}`)}{' '}
              </Button>
            </Grid>
          )
        case 'Stepper':
          return (
            <Grid key={item.id} item xs={12}>
              <ProgressMobileStepper color="primary" actualStep={props.step - 1} steps={numSteps}></ProgressMobileStepper>
            </Grid>
          )
        case 'TimePicker': {
          let helperText = ''
          if (errorsValidate[item.id] === undefined) errorsValidate[item.id] = false
          if (errorsValidate[item.id] === true && item.helperText) helperText = t(item.helperText.toString())
          if (errorsRequired[item.id] === undefined) errorsRequired[item.id] = false
          if (errorsRequired[item.id] === true) helperText = t('required')
          let error = errorsValidate[item.id] || errorsRequired[item.id]
          return (
            <Grid key={item.id} item xs={12} sm={item.size}>
              <TextField
                placeholder="hh:mm"
                color="primary"
                value={values[item.id]}
                fullWidth
                id={`${item.id}`}
                variant="outlined"
                helperText={helperText}
                error={error}
                name={`${item.label}`}
                label={t(`${item.label}`)}
                required={true}
                className={classes.textField}
                onChange={(e) => handleInputChangePickers(e.target.value, item.id, 1)}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }}
              />
            </Grid>
          )
        }
        case 'DatePicker': {
          if (values[item.id] === undefined) setValues({ ...values, [item.id]: moment(new Date(), 'MMM-DD-YYYY').format() })
          return (
            <Grid key={item.id} item xs={12} sm={item.size}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <Grid container className={classes.grid} justify="space-around">
                  <DatePicker
                    color="primary"
                    minDate={new Date()}
                    maxDate={new Date().setFullYear(new Date().getFullYear() + 4)}
                    format="dd/MM/yyyy"
                    disabled={item.disabled}
                    variant="outlined"
                    error={error}
                    helperText={helperText}
                    required={item.required}
                    id={`${item.id}`}
                    name={`${item.label}`}
                    label={t(`${item.label}`)}
                    fullWidth
                    value={values[item.id]}
                    onChange={(e) => handleInputChangePickers(e, item.id, 2)}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
          )
        }
        case 'Image':
          return (
            <Grid key={item.id} item xs={12}>
              <img src={`../img/${item.name}.svg`} height={item.height} width={item.width}></img>
            </Grid>
          )
        default:
          return <div />
      }
    }
  })
}

export default function Formulary(props) {
  const classes = useStyles()
  return (
    <React.Fragment>
      <Grid className={classes.root} container spacing={1} direction="column">
        <RenderFormulary
          previusData={props.previusData}
          expired={props.expired}
          clientId={props.clientId}
          setLastStep={props.setLastStep}
          idLlamada={props.idLlamada}
          setFirstStep={props.setFirstStep}
          lastStep={props.lastStep}
          goBack={props.goBack}
          cancel={props.cancel}
          setCanceled={props.setCanceled}
          step={props.step}
          setStep={props.setStep}
        ></RenderFormulary>
      </Grid>
    </React.Fragment>
  )
}
