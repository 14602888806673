const regExr = {
  email: '^[^@]+@[^@]+.[a-zA-Z]{2,}$',
  phone: '(+34|0034|34)?[ -]*(6|7)[ -]*([0-9][ -]*){8}',
  dni:
    '((([x-zX-Z])|([LM])){1}([-]?)((\\d){7})([-]?)([a-zA-Z]{1}))|((\\d{8})([-]?)([a-zA-Z]))|^[a-zA-Z]{1}\\d{7}[a-zA-Z0-9]{1}$|^[XxTtYyZz]{1}[0-9]{7}[a-zA-Z]{1}$|((\\d{8})([-]?)([A-Za-z]))|^[xyzXYZ]\\d{7,8}[a-zA-Z]$|^([abcdefghjklmnpqrsuvwABCDEFGHJKLMNPQRSUVW])(\\d{7})([0-9A-Ja-j])$',
  matricula: `(^([a-zA-Z]{1,2})\\d{4}([a-zA-Z]{1,2})$)|(^\\d{4}([a-zA-Z]{3})$)|(^([a-zA-Z]{2})\\d{4}([a-zA-Z]{1})$)|(^([a-zA-Z]{3})\\d{4}$)`,
  hora24: `^([01]?[0-9]|2[0-3]):[0-5][0-9]$`,
}

var pantalla_link_error = {
  title: 'Titulo 1',
  id: 'test',
  values: [
    {
      variant: 'h5',
      label: 'error_link',
      id: 'text1',
      size: 12,
      typeField: 'Text',
    },
  ],
}

var pantalla_error = {
  title: 'Titulo 1',
  id: 'test',
  values: [
    {
      variant: 'h5',
      label: 'error_send',
      id: 'text1',
      size: 12,
      typeField: 'Text',
    },
  ],
}

var pantalla_baja = {
  title: 'Titulo 1',
  id: 'test',
  values: [
    {
      variant: 'h5',
      label: 'baja_text',
      id: 'text1',
      size: 12,
      typeField: 'Text',
    },
  ],
}

var pantalla1 = {
  endpoint: 'validezasegurado',
  title: 'Titulo 1',
  id: 'test',
  values: [
    {
      id: 'Stepper',
      typeField: 'Stepper',
    },
    {
      variant: 'body1',
      label: 'welcome',
      id: 'text1',
      size: 12,
      typeField: 'Text',
    },

    {
      variant: 'h5',
      label: 'title_screen_1',
      id: 'text2',
      size: 12,
      typeField: 'Text',
    },
    /*{
      label: 'name',
      maxLength: 20,
      id: 'name',
      size: 12,
      typeField: 'TextInput',
      required: true,
    },
    {
      label: 'lastName',
      maxLength: 60,
      id: 'surname',
      size: 12,
      typeField: 'TextInput',
      required: true,
    }*/
    {
      label: 'doc_identity',
      id: 'dni',
      size: 12,
      typeField: 'TextInput',
      helperText: 'incorrect_entry_dni',
      regExr: regExr.dni,
      required: true,
    },
    {
      label: 'plate',
      id: 'carLicense',
      size: 12,
      typeField: 'TextInput',
      helperText: 'incorrect_entry_plate',

      required: true,
    },
    {
      notRender: true,
      typeField: 'TextInput',
      label: '',
      id: 'company',
      size: 12,
      disabled: true,

      required: false,
    },

    {
      label: 'next',
      id: 'Send',
      typeField: 'Button',
    },
  ],
}

var pantalla1_1 = {
  endpoint: 'validezasegurado',
  title: 'Titulo 1',
  id: 'test',
  alert: 'review',
  values: [
    {
      id: 'Stepper',
      typeField: 'Stepper',
    },
    {
      variant: 'body1',
      label: 'review',
      id: 'text1',
      size: 12,
      typeField: 'Text',
    },
    {
      variant: 'h5',
      label: 'title_screen_1',
      id: 'text2',
      size: 12,
      typeField: 'Text',
    } /*,
    {
      label: 'name',
      id: 'name',
      maxLength: 20,
      size: 12,
      typeField: 'TextInput',
      required: true,
    },
    {
      label: 'lastName',
      id: 'surname',
      maxLength: 60,
      size: 12,
      typeField: 'TextInput',
      required: true,
    }*/,
    {
      label: 'doc_identity',
      id: 'dni',
      size: 12,
      typeField: 'TextInput',
      helperText: 'incorrect_entry_dni',
      regExr: regExr.dni,
      required: true,
    },
    {
      label: 'plate',
      id: 'carLicense',
      size: 12,
      typeField: 'TextInput',
      helperText: 'incorrect_entry_plate',

      required: true,
    },
    {
      notRender: true,
      typeField: 'TextInput',
      label: '',
      id: 'company',
      size: 12,
      disabled: true,

      required: false,
    },

    {
      label: 'Corregir datos',
      id: 'Send',
      typeField: 'Button',
    },
  ],
}

var pantalla1_2 = {
  title: 'Titulo 1',
  id: 'test',

  values: [
    {
      variant: 'body1',
      label: `error_data`,
      id: 'text1',
      size: 12,
      typeField: 'Text',
    },
    {
      label: 'Salir',
      id: 'Send',
      typeField: 'Button',
    },
  ],
}

var pantalla2 = {
  resume: true,
  id: 'p2',
  values: [
    {
      id: 'Stepper',
      typeField: 'Stepper',
    },
    {
      variant: 'h5',
      label: 'title_screen_2',
      id: 'text1',
      size: 12,
      typeField: 'Text',
    },
    {
      label: 'name_tomador',
      id: 'tomador',
      disabled: true,
      size: 12,
      typeField: 'TextInput',
      required: true,
    },
    {
      label: 'plate_2',
      disabled: true,
      id: 'carLicense',
      size: 12,

      typeField: 'TextInput',
      helperText: 'incorrect_entry_plate',
      required: true,
    },
    {
      variant: 'h5',
      label: 'title_screen_2_1',
      id: 'text2',
      size: 12,
      typeField: 'Text',
    },
    {
      typeField: 'Checkbox',
      label: 'immediate',
      defaultValue: true,
      selector: true,
      valuesSelector: ['right_now', 'other_moment'],
      id: 'immediate',
      size: 12,
      required: true,
      hideChildrens: ['dia', 'hora'],
    },
    {
      notRender: true,
      typeField: 'DatePicker',
      label: 'day',
      id: 'dia',
      size: 12,
      required: true,
    },
    {
      notRender: true,
      helperText: 'two_hours',
      regExr: regExr.hora24,
      timePicker: true,
      typeField: 'TimePicker',
      label: 'hour',
      id: 'hora',
      size: 12,
      required: true,
    },

    {
      label: 'next',
      id: 'Send',
      typeField: 'Button',
    },
  ],
}

var pantalla2_1 = {
  id: 'p2',
  values: [
    {
      variant: 'h5',
      label: 'title_legal',
      id: 'text1',
      size: 12,
      typeField: 'Text',
    },
    {
      label: 'aviso legal',
      id: 'aviso-legal',
      size: 12,
      disabled: true,
      typeField: 'TextArea',
      legalText: true,
      defaultValueSegurosBilbao: 'legal_text_SegurosBilbao',
      defaultValuePlusUltra: 'legal_text_PlusUltra',
      defaultValueHelvetia: 'legal_text_Helvetia',
      dafaultValueGES: 'legal_text_GES',
      defaultValueMGS: 'legal_text_MGS',
      dafaultValueUnionAlcoyana: 'legal_text_UnionAlcoyana',
      defaultValueReale: 'legaltext_reale',
      defaultValueAsitur: 'legaltext_asitur',
      defaultValueCatalana: 'legaltext_catalana',
      required: false,
    },
    {
      label: 'acept',
      id: 'Send',
      typeField: 'Button',
    },
  ],
}

var pantalla3 = {
  id: 'p2',
  //alertFinal: 'ubiTextHelp',
  values: [
    {
      id: 'Stepper',
      typeField: 'Stepper',
    },
    {
      variant: 'h5',
      label: 'title_screen_3',
      id: 'text1',
      size: 12,
      typeField: 'Text',
    },
    {
      typeField: 'Checkbox',
      label: 'noStart',
      showChildrens: ['noStartRedLightBattery'],
      id: 'noStart',
      group: 'fault',
      code: '0',
      groupIds: ['noStart', 'noBattery', 'noFuel', 'keysInside', 'flatTires', 'otherFault'],
      size: 12,
      required: false,
    },
    {
      label: 'lightBatery',
      id: 'noStartRedLightBattery',
      notRender: true,
      size: 12,
      typeField: 'Selector',
      required: true,
      options: [
        {
          label: 'yes',
          value: 0,
        },
        {
          label: 'no',
          value: 1,
        },
        {
          label: 'no_se',
          value: 2,
        },
      ],
    },
    {
      typeField: 'Checkbox',
      label: 'batery',
      showChildrens: ['noBatteryRedLightBattery'],
      hideChildrens: ['noStartRedLightBattery'],
      id: 'noBattery',
      group: 'fault',
      code: '2',
      groupIds: ['noStart', 'noBattery', 'noFuel', 'keysInside', 'flatTires', 'otherFault'],
      size: 12,
      required: false,
    },
    {
      label: 'lightBatery',
      id: 'noBatteryRedLightBattery',
      notRender: true,
      size: 12,
      typeField: 'Selector',
      required: true,
      options: [
        {
          label: 'yes',
          value: 0,
        },
        {
          label: 'no',
          value: 1,
        },
        {
          label: 'no_se',
          value: 2,
        },
      ],
    },
    {
      typeField: 'Checkbox',
      label: 'fuel',
      group: 'fault',
      code: '4',
      groupIds: ['noStart', 'noBattery', 'noFuel', 'keysInside', 'flatTires', 'otherFault'],
      id: 'noFuel',
      size: 12,
      required: false,
      hideChildrens: ['noStartRedLightBattery'],
    },
    {
      typeField: 'Checkbox',
      label: 'keys',
      id: 'keysInside',
      group: 'fault',
      code: '3',
      groupIds: ['noStart', 'noBattery', 'noFuel', 'keysInside', 'flatTires', 'otherFault'],
      size: 12,
      required: false,
      hideChildrens: ['noStartRedLightBattery'],
    },
    {
      typeField: 'Checkbox',
      label: 'wheelsCheckbox',
      id: 'flatTires',
      showChildrens: ['flatTiresCount', 'moreWheels'],
      hideChildrens: ['noStartRedLightBattery'],
      group: 'fault',
      code: '1',
      groupIds: ['noStart', 'noBattery', 'noFuel', 'keysInside', 'flatTires', 'otherFault'],
      size: 12,
      required: false,
    },
    {
      label: 'wheels',
      id: 'flatTiresCount',
      notRender: true,
      size: 12,
      typeField: 'Selector',
      required: true,
      options: [
        {
          label: '1',
          value: 1,
        },
        {
          label: '2',
          value: 2,
        },
        {
          label: '3',
          value: 3,
        },
        {
          label: '4',
          value: 4,
        },
      ],
    },
    {
      label: 'moreWheels',
      id: 'moreWheels',
      notRender: true,
      size: 12,
      typeField: 'Selector',
      required: true,
      options: [
        {
          label: `yes`,
          value: `true`,
        },
        {
          label: `no`,
          value: `false`,
        },
      ],
    },
    {
      typeField: 'Checkbox',
      label: 'other',
      group: 'fault',
      code: '5',
      showChildrens: ['otherFaultRemark'],
      hideChildrens: ['noStartRedLightBattery'],
      groupIds: ['noStart', 'noBattery', 'noFuel', 'keysInside', 'flatTires', 'otherFault'],
      id: 'otherFault',
      size: 12,
      required: false,
    },
    {
      label: 'observationsOther',
      notRender: true,
      maxLength: 250,
      id: 'otherFaultRemark',
      size: 12,
      typeField: 'TextArea',
      required: true,
    },
    {
      label: 'observations',
      maxLength: 250,
      id: 'faultRemark',
      size: 12,
      typeField: 'TextArea',
      required: false,
    },

    {
      label: 'next',
      id: 'Send',
      typeField: 'Button',
    },
  ],
}

var pantalla4 = {
  id: 'p2',
  values: [
    {
      id: 'Stepper',
      typeField: 'Stepper',
    },
    {
      variant: 'h5',
      label: 'title_screen_4_1',
      id: 'text1',
      size: 12,
      typeField: 'Text',
    },
    {
      variant: 'h7',
      label: 'title_screen_4',
      id: 'text1',
      size: 12,
      typeField: 'Text',
    },
    {
      label: 'ubi',
      id: 'geo_ubicacion',
      size: 12,
      typeField: 'geoPosition',
      required: false,
    },

    {
      label: 'ubi_body',
      id: 'roadType',
      size: 12,
      typeField: 'Selector',
      required: true,
      showChildrens: ['undergroundParking'],
      showChildrensIfValue: 'U',

      options: [
        {
          label: 'highway',
          value: 'P',
        },
        {
          label: 'city',
          value: 'U',
        },
      ],
    },
    {
      notRender: true,
      typeField: 'Checkbox',
      label: 'parking',
      selector: true,
      id: 'undergroundParking',
      size: 12,
      required: false,
    },
    {
      label: 'observations',
      maxLength: 250,
      id: 'ubiRemark',
      size: 12,
      typeField: 'TextArea',
      required: false,
    },

    {
      label: 'next',
      id: 'Send',
      typeField: 'Button',
    },
  ],
}

var pantalla5 = {
  id: 'p2',
  values: [
    {
      id: 'Stepper',
      typeField: 'Stepper',
    },
    {
      variant: 'h5',
      label: 'title_screen_5',
      id: 'text1',
      size: 12,
      typeField: 'Text',
    },
    {
      variant: 'h7',
      label: 'title_screen_5_1',
      id: 'text2',
      size: 12,
      typeField: 'Text',
    },

    {
      label: 'number_persons',
      id: 'personCount',
      size: 12,
      defaultValue: 1,
      helperText: 'sume',
      typeField: 'Selector',
      required: true,
      options: [
        {
          label: '1',
          value: 1,
        },
        {
          label: '2',
          value: 2,
        },
        {
          label: '3',
          value: 3,
        },
        {
          label: '4',
          value: 4,
        },
        {
          label: '5',
          value: 5,
        },
        {
          label: '6',
          value: 6,
        },
        {
          label: '7',
          value: 7,
        },
        {
          label: '8',
          value: 8,
        },
      ],
    },
    {
      label: 'adults',
      id: 'adultCount',
      size: 12,
      defaultValue: 1,
      helperText: 'sume',
      typeField: 'Selector',
      required: true,
      optionsId: ['personCount', 'children'],
      options: [
        {
          label: '1',
          value: 1,
        },
        {
          label: '2',
          value: 2,
        },
        {
          label: '3',
          value: 3,
        },
        {
          label: '4',
          value: 4,
        },
        {
          label: '5',
          value: 5,
        },
        {
          label: '6',
          value: 6,
        },
        {
          label: '7',
          value: 7,
        },
        {
          label: '8',
          value: 8,
        },
      ],
    },
    {
      label: 'boys',
      id: 'children',
      size: 12,
      defaultValue: 0,
      typeField: 'Selector',
      helperText: 'sume',
      optionsId: ['personCount', 'adultCount'],
      options: [
        {
          label: '0',
          value: 0,
        },
        {
          label: '1',
          value: 1,
        },
        {
          label: '2',
          value: 2,
        },
        {
          label: '3',
          value: 3,
        },
        {
          label: '4',
          value: 4,
        },
        {
          label: '5',
          value: 5,
        },
        {
          label: '6',
          value: 6,
        },
        {
          label: '7',
          value: 7,
        },
        {
          label: '8',
          value: 8,
        },
      ],
    },
    {
      typeField: 'Checkbox',
      selector: true,
      label: 'special_needs',
      id: 'specialNeeds',
      size: 12,
      required: false,
    },
    {
      variant: 'h7',
      label: 'title_screen_5_2',
      id: 'text3',
      size: 12,
      typeField: 'Text',
    },

    {
      label: 'observations',
      maxLength: 250,
      id: 'specialRemark',
      size: 12,
      typeField: 'TextArea',
      required: false,
    },

    {
      label: 'next',
      id: 'Send',
      typeField: 'Button',
    },
  ],
}

var pantalla6 = {
  id: 'p6',
  resume: true,
  values: [
    {
      id: 'Stepper',
      typeField: 'Stepper',
    },
    {
      variant: 'h5',
      label: 'title_screen_6',
      id: 'text1',
      size: 12,
      typeField: 'Text',
    },
    {
      label: 'plate',
      id: 'carLicense',
      size: 12,
      typeField: 'TextInput',
      helperText: 'incorrect_entry_plate',
      disabled: true,
    },
    {
      label: 'type_fault',
      id: 'fault',
      size: 12,
      typeField: 'TextInput',
      disabled: true,
    },
    {
      label: 'ubi',
      id: 'geoPosition',
      size: 12,
      typeField: 'TextInput',
      disabled: true,
    },
    {
      label: 'number_persons',
      id: 'personCount',
      size: 12,
      typeField: 'TextInput',
      no_traslation: true,
      disabled: true,
    },
    {
      label: 'type_ubi',
      id: 'roadType',
      size: 12,
      typeField: 'TextInput',

      disabled: true,
    },
    {
      notRender: false,
      typeField: 'Checkbox',
      label: 'parking',
      selector: true,
      id: 'undergroundParking',
      size: 12,
      disabled: true,
    },

    {
      label: 'confirmed',
      id: 'Send',
      typeField: 'Button',
    },
  ],
}

var pantalla6_1 = {
  id: 'p61',
  alert: 'review',
  values: [
    {
      id: 'Stepper',
      typeField: 'Stepper',
    },
    {
      variant: 'h5',
      label: 'title_screen_6',
      id: 'text1',
      size: 12,
      typeField: 'Text',
    },
    {
      label: 'plate',
      id: 'carLicense',
      size: 12,
      typeField: 'TextInput',
      helperText: 'incorrect_entry_plate',
      disabled: true,
    },
    {
      label: 'type_fault',
      id: 'fault',
      size: 12,
      typeField: 'TextInput',
      disabled: true,
    },
    {
      label: 'ubi',
      id: 'address',
      size: 12,
      typeField: 'TextInput',
      disabled: true,
    },
    {
      label: 'number_persons',
      id: 'personCount',
      size: 12,
      typeField: 'TextInput',
      no_traslation: true,
      disabled: true,
    },
    {
      label: 'type_ubi',
      id: 'roadType',
      size: 12,
      typeField: 'TextInput',

      disabled: true,
    },
    {
      notRender: false,
      typeField: 'Checkbox',
      label: 'parking',
      selector: true,
      id: 'undergroundParking',
      size: 12,
      disabled: true,
    },
  ],
}

var pantalla7 = {
  id: 'p2',
  values: [
    {
      variant: 'h5',
      label: 'title_screen_7',
      id: 'text1',
      size: 12,
      typeField: 'Text',
    },
    {
      variant: 'body2',
      label: 'end_text',
      id: 'text2',
      size: 12,
      typeField: 'Text',
    },
    {
      variant: 'body2',
      label: 'tft_text',
      id: 'text3',
      size: 12,
      typeField: 'Text',
    },
  ],
}

export {
  pantalla_baja,
  pantalla1,
  pantalla1_1,
  pantalla1_2,
  pantalla2,
  pantalla2_1,
  pantalla3,
  pantalla4,
  pantalla5,
  pantalla6,
  pantalla6_1,
  pantalla7,
  pantalla_link_error,
  pantalla_error,
}
