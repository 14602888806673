import React, { useState, useEffect } from 'react'
import './App.css'
import CardFormulary from './components/cardFormulary'
import { Grid, CircularProgress } from '@material-ui/core'
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles'
import ChangeLanguage from './components/ChangeLanguage'
import { useTranslation } from 'react-i18next'
import { checkAnulable } from '../src/services/data/anulableService'
import { entidadSinister } from '../src/store/anulableTypes'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: '5%',
  },
}))

const theme_reale = createMuiTheme({
  palette: {
    primary: {
      main: '#5882FA',
      contrastText: '#fff',
    },
    secondary: {
      main: '#f44336',
      contrastText: '#000',
    },
  },
})

const theme_catalana = createMuiTheme({
  palette: {
    primary: {
      main: '#DC0028',
      contrastText: '#fff',
    },
    secondary: {
      main: '#DC0028',
      contrastText: '#000',
    },
  },
})

const theme_asitur = createMuiTheme({
  palette: {
    primary: {
      main: '#169bd7',
      contrastText: '#fff',
    },
    secondary: {
      main: '#169bd7',
      contrastText: '#000',
    },
  },
})
const theme_union_alcoyana = createMuiTheme({
  palette: {
    primary: {
      main: '#014689',
      contrastText: '#fff',
    },
    secondary: {
      main: '#AE457E',
      contrastText: '#000',
    },
  },
})
const theme_mgs = createMuiTheme({
  palette: {
    primary: {
      main: '#001945',
      contrastText: '#fff',
    },
    secondary: {
      main: '#EC7F0A',
      contrastText: '#000',
    },
  },
})
const theme_helvetia = createMuiTheme({
  palette: {
    primary: {
      main: '#00B6BE',
      contrastText: '#fff',
    },
    secondary: {
      main: '#EB0006',
      contrastText: '#000',
    },
  },
})

const theme_ges = createMuiTheme({
  palette: {
    primary: {
      main: '#C40020',
      contrastText: '#fff',
    },
    secondary: {
      main: '#DCD3C0',
      contrastText: '#000',
    },
  },
})

function App() {
  const [t, i18n] = useTranslation('global')

  const [expired, setExpired] = useState(undefined)
  const [clientId, setClientId] = useState(undefined)
  const [idLlamada, setIdLlamada] = useState(undefined)
  const [finalTheme, setFinalTheme] = useState(undefined)
  const [previusData, setPreviusData] = useState(undefined)
  const [idScreen, setIdScreen] = useState('1')
  //componentdidmount

  useEffect(() => {
    const fullUrl = window.location.href

    if (fullUrl.includes('expeditionTime' && 'clientId' && 'IdLlamada')) {
      const urlParams = new URLSearchParams(window.location.search)

      var auxPad0 = urlParams.get('clientId')
      auxPad0 = auxPad0.length < 8 ? auxPad0.padStart(8, 0) : auxPad0
      const aux = auxPad0

      switch (auxPad0) {
        case '00000025':
          setFinalTheme(theme_reale)
          break
        case '00000016':
          setFinalTheme(theme_asitur)
          break
        case '00000002':
          setFinalTheme(theme_catalana)
          break
        case '00000001':
          setFinalTheme(theme_catalana)
          break
        case '00000020':
          setFinalTheme(theme_catalana)
          break
        case '00000007':
          setFinalTheme(theme_union_alcoyana)
          break
        case '00000010':
          setFinalTheme(theme_mgs)
          break
        case '00000011':
          setFinalTheme(theme_helvetia)
          break
        case '00000006':
          setFinalTheme(theme_ges)

          break
        default:
          setFinalTheme(theme_asitur)
          break
      }
      console.log(aux)
      let clientIdLet = urlParams.get('clientId')
      setClientId(clientIdLet)
      setIdLlamada(urlParams.get('IdLlamada'))
      const expirationTime = urlParams.get('expeditionTime')

      const lang = urlParams.get('lg')
      i18n.changeLanguage(lang)

      fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/register/caducidadlink?clientId=${clientIdLet}&expeditionTime=${expirationTime}`, {
        mode: 'cors',
        method: 'GET',
        headers: {
          Accept: '*/*',
        },
      })
        .then((res) => {
          if (res.status === 200) {
            setExpired(false)

            let entidadAnulable = entidadSinister
            entidadAnulable.ClientId = auxPad0
            entidadAnulable.IdLlamada = urlParams.get('IdLlamada')
            let anulacion = checkAnulable(entidadAnulable)
            anulacion
              .then((res) => console.log('ca1-> ' + res))
              .catch((error) => {
                console.error('Error:', error)
              })

            fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/register/getscreenuser?clientId=${clientIdLet}&callId=${urlParams.get('IdLlamada')}`, {
              mode: 'cors',
              method: 'GET',
              headers: {
                Accept: '*/*',
              },
            })
              .then((res) => res.json())
              .then((res2) => {
                setPreviusData(res2)
              })
              .catch((error) => {})
          } else setExpired(true)
        })
        .catch((error) => {
          setExpired(true)
        })
    } else {
      const params = fullUrl.split('/')
      let expeditionTimeLet = ''
      let clientIdLet = ''
      let IdLlamadaLet = ''
      let lang = ''
      if (params.length >= 6) {
        expeditionTimeLet = params[3]
        clientIdLet = params[4]
        IdLlamadaLet = params[5]
        lang = params[6]
      }
      i18n.changeLanguage(lang)
      const urlParams = new URLSearchParams(window.location.search)
      var auxPad0 = clientIdLet
      auxPad0 = auxPad0.length < 8 ? auxPad0.padStart(8, 0) : auxPad0
      const aux = auxPad0

      switch (auxPad0) {
        case '00000025':
          setFinalTheme(theme_reale)
          break
        case '00000016':
          setFinalTheme(theme_asitur)
          break
        case '00000002':
          setFinalTheme(theme_catalana)
          break
        case '00000001':
          setFinalTheme(theme_catalana)
          break
        case '00000020':
          setFinalTheme(theme_catalana)
          break
        case '00000007':
          setFinalTheme(theme_union_alcoyana)
          break
        case '00000010':
          setFinalTheme(theme_mgs)
          break
        case '00000011':
          setFinalTheme(theme_helvetia)
          break
        case '00000006':
          setFinalTheme(theme_ges)
          break
        default:
          setFinalTheme(theme_asitur)
          break
      }
      console.log(clientIdLet, IdLlamadaLet)
      setClientId(clientIdLet)
      setIdLlamada(IdLlamadaLet)

      const expirationTime = expeditionTimeLet
      console.log(clientId)
      fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/register/caducidadlink?clientId=${clientIdLet}&expeditionTime=${expirationTime}`, {
        mode: 'cors',
        method: 'GET',
        headers: {
          Accept: '*/*',
        },
      })
        .then((res) => {
          if (res.status === 200) {
            setExpired(false)
            fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}/api/register/getscreenuser?clientId=${clientIdLet}&callId=${IdLlamadaLet}`, {
              mode: 'cors',
              method: 'GET',
              headers: {
                Accept: '*/*',
              },
            })
              .then((res) => res.json())
              .then((res2) => {
                setPreviusData(res2)
              })
              .catch((error) => {})

            let entidadAnulable = entidadSinister
            entidadAnulable.ClientId = auxPad0
            entidadAnulable.IdLlamada = IdLlamadaLet

            let anulacion = checkAnulable(entidadAnulable)
            anulacion
              .then((res) => console.log('ca1-> ' + res))
              .catch((error) => {
                console.error('Error:', error)
              })
          } else setExpired(true)
        })
        .catch((error) => {
          setExpired(true)
        })
    }
  }, [])

  const classes = useStyles()

  return (
    <div className="App">
      {idLlamada && finalTheme ? (
        <div className={classes.root}>
          <Grid container direction="row" justify="center" alignItems="center">
            <ThemeProvider theme={finalTheme}>
              {expired === undefined ? (
                <CircularProgress></CircularProgress>
              ) : (
                [<CardFormulary previusData={previusData} expired={expired} clientId={clientId} idLlamada={idLlamada} idScreen={idScreen}></CardFormulary>, <ChangeLanguage />]
              )}
              <div></div>
            </ThemeProvider>
          </Grid>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  )
}

export default App
