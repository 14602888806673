import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Formulary from './formulary'
import FormDialog from './dialog'
import { useTranslation } from 'react-i18next'
import { IconButton } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import steps from '../steps'
import reale_logo from '../img/reale_logo.png'
import asitur_logo from '../img/asitur_logo.png'
import catalana_logo from '../img/catalana_logo.png'
import seguros_bilbao_logo from '../img/seguros_bilbao_logo.png'
import plus_ultra_logo from '../img/plus_ultra_logo.png'
import union_alcoyana_logo from '../img/union_alcoyana_logo.png'
import mgs_logo from '../img/mgs_logo.png'
import helvetia_logo from '../img/helvetia_logo.jpg'
import ges_logo from '../img/ges_logo.png'

const useStyles = makeStyles({
  cardcontent: {
    width: '95%',
    marginTop: -20,
  },
  root: {
    width: '95%',
    alignContent: 'center',
    borderRadius: 10,
    minWidth: 275,
    maxWidth: 1200,
    boxShadow: '0px 10px 13px -7px #BDBDBD',
  },
  footer: {
    marginTop: '2%',
  },
  buttonCancel: {
    textDecoration: 'underline',
    fontSize: 13,
    textDecorationColor: '#6E6E6E',
    color: '#6E6E6E',
  },
  media: {
    margin: 10,
    paddingTop: '15%', // 16:9,
    width: '50%',
  },
})

function CardFormulary(props) {
  const { t } = useTranslation()
  const [openAnular, setOpenAnular] = useState(false)
  const [openCancelar, setOpenCancelar] = useState(false)

  const [step, setStep] = useState('1')
  const [lastStep, setLastStep] = useState(false)
  const [firstStep, setFirstStep] = useState(false)
  const [backButton, setBackButton] = useState(false)
  const [canceled, setCanceled] = useState(false)
  const [previusData, setPreviusData] = useState(undefined)

  useEffect(() => {
    if (props.previusData != undefined && previusData == undefined) {
      setPreviusData(props.previusData)
      checkPreviusData(props.previusData)
    }
  })

  function sendAnularProcess() {
    setStep('0_1')
    checkCancellable()
  }

  function sendCancelProcess() {
    setStep('1')
    setCanceled(true)
    sendCancelar()
  }

  function handleAceptAnular() {
    setOpenAnular(false)
    sendAnularProcess()
  }

  function handleAceptCancelar() {
    setOpenCancelar(false)

    sendCancelProcess()
  }

  function handleCancel() {
    setOpenAnular(false)
    setOpenCancelar(false)
  }

  function goBack() {
    setStep(steps.steps[step].previusStep)
  }

  function checkPreviusData(previusData) {
    let stepAux = '1'

    if (previusData.dni != undefined && previusData.dni != '') stepAux = '1'
    if (previusData.carLicense != undefined && previusData.carLicense != '') stepAux = '2'
    if (previusData.noBattery != undefined && previusData.noBattery != '') stepAux = '4'
    if (previusData.latitude != undefined && previusData.latitude != '') stepAux = '5'
    if (previusData.personCount != undefined && previusData.personCount != '') stepAux = '6'

    setStep(stepAux)
  }

  function checkBackButton() {
    if (lastStep || step == '1' || step == '1_2' || step == '7_1' || step == '8' || step == '0_1' || step == '0') return false
    else return true
  }
  function logoClient(clientId) {
    switch (clientId) {
      case '00000025':
        return reale_logo

      case '00000016':
        return asitur_logo

      case '00000002':
        return catalana_logo

      case '00000001':
        return seguros_bilbao_logo

      case '00000020':
        return plus_ultra_logo

      case '00000007':
        return union_alcoyana_logo

      case '00000010':
        return mgs_logo

      case '00000011':
        return helvetia_logo

      case '00000006':
        return ges_logo

      default:
        return asitur_logo
    }
  }
  function sendCancelar() {
    let url = `/api/register/cancelacionincidencia?clientId=${props.clientId}&callId=${props.idLlamada}`
    fetch(process.env.REACT_APP_API_URL + url)
  }

  function checkCancellable() {
    let url = '/api/register/checkanulacionincidencia'
    let data = props

    fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}${url}`, {
      mode: 'cors',
      method: 'POST',
      body: JSON.stringify({
        ...data,
      }),
      headers: {
        'Content-Type': 'application/json',
        Accept: '*/*',
      },
    })
      .then((res) => res.json())
      .catch((error) => {
        console.error('Error:', error)
      })
      .then((response) => {
        if (response) {
          cancelIncidence()
        } else {
        }
      })
    return
  }

  function cancelIncidence() {
    let url = '/api/register/anulacionincidencia'
    let data = props

    fetch(`${process.env.REACT_APP_API_URL}:${process.env.REACT_APP_API_PORT}${url}`, {
      mode: 'cors',
      method: 'POST',
      body: JSON.stringify({
        ...data,
      }),
      headers: {
        'Content-Type': 'application/json',
        Accept: '*/*',
      },
    })
      .then((res) => res.json())
      .catch((error) => {
        console.error('Error:', error)
      })
      .then((response) => {
        if (response.codigo == '221') {
          //mensaje anulacion correcta?
          console.log('ANULACION CORRECTA')
        } else {
          //mensaje error?
        }
      })
    return
  }

  const classes = useStyles()

  return (
    <Grid container direction="row" justify="center" alignItems="center">
      <Card className={classes.root} square={false}>
        {checkBackButton() ? (
          <IconButton aria-label="delete" className={classes.margin} size="large" onClick={goBack} style={{ position: 'absolute' }}>
            <ArrowBackIcon fontSize="large" color="primary" />
          </IconButton>
        ) : (
          <Grid></Grid>
        )}

        <Grid container direction="row" justify="center" alignItems="center">
          <CardMedia className={classes.media} image={logoClient(props.clientId)} />
          <CardContent className={classes.cardcontent}>
            <Formulary
              previusData={previusData}
              step={step}
              setStep={setStep}
              expired={props.expired}
              clientId={props.clientId}
              idLlamada={props.idLlamada}
              setLastStep={setLastStep}
              setFirstStep={setFirstStep}
              lastStep={lastStep}
              cancel={canceled}
              setCanceled={setCanceled}
              goBack={goBack}
            ></Formulary>
          </CardContent>
          <CardActions></CardActions>
        </Grid>
      </Card>
      <Grid container direction="row" justify="center" alignItems="center" className={classes.footer}>
        {!props.expired ? (
          <Grid item>
            {(lastStep || step == '1_2' || step == '7_1' || step == '8') && (props.IsCancellable == null || props.IsCancellable) ? (
              <Button size="large" variant="text" className={classes.buttonCancel} color="default" onClick={() => setOpenAnular(true)}>
                {t('null_formulary')}
              </Button>
            ) : step != '0_1' ? (
              <Button size="large" variant="text" className={classes.buttonCancel} color="default" onClick={() => setOpenCancelar(true)}>
                {t('cancel_formulary')}
              </Button>
            ) : (
              <Grid></Grid>
            )}
          </Grid>
        ) : undefined}
        <FormDialog open={openAnular} handleAcept={handleAceptAnular} handleCancel={handleCancel}></FormDialog>
        <FormDialog open={openCancelar} handleAcept={handleAceptCancelar} handleCancel={handleCancel}></FormDialog>
      </Grid>
    </Grid>
  )
}

export default CardFormulary
