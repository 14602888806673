import React from 'react'

const mapStyles = {
  map: {
    position: 'relative',

    maxWidth: '100%',
    height: '40vh',
  },
}
export class CurrentLocation extends React.Component {
  constructor(props) {
    super(props)
    this.mapRef = React.createRef()
    const { lat, lng } = this.props.initialCenter
    this.state = {
      zoom: 5,
      update: 1,
      currentLocation: {
        lat,
        lng,
      },
      manual: false
    }
  }
  componentDidMount() {
    if (this.props.centerAroundCurrentLocation) {
      if (navigator && navigator.geolocation && !this.props.adress) {
        navigator.geolocation.getCurrentPosition((pos, err) => {
          const coords = pos.coords
          this.props.updateLatLng(coords.latitude, coords.longitude)
          const cntx = this
          fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${coords.latitude},${coords.longitude}&key=${process.env.REACT_APP_GOOGLE_GEOCODING_KEY}`)
            .then(function (response) {
              return response.json()
            })
            .then(function (myJson) {
              cntx.props.updateLatLng(coords.latitude, coords.longitude)
              cntx.props.updateAdress(myJson.results[0].formatted_address)
            })

          this.setState({
            zoom: 16,
            currentLocation: {
              lat: coords.latitude,
              lng: coords.longitude,
            },
          })
        })
      } else if (this.props.adress) this.props.updateAdress(this.props.adress)
      else {
      }
    }
    this.loadMap()
    this.recenterMap()
    return true
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.google !== this.props.google) {
      this.loadMap()
    }
    if (this.props.latitude && this.props.longitude && !this.props.manual)
      if (this.state.currentLocation.lat != this.props.latitude || this.state.currentLocation.lng != this.props.longitude) {
        this.setState(
          {
            zoom: 16,
            currentLocation: {
              lat: this.props.latitude,
              lng: this.props.longitude
            },
          },
          () => {
            this.loadMap()
          },
        )
        this.loadMap()
      }
    if (prevState.currentLocation !== this.state.currentLocation) {
      this.recenterMap()
    }
  }

  loadMap() {
    if (this.props && this.props.google) {
      // checks if google is available
      const { google } = this.props

      const maps = google.maps

      // reference to the actual DOM element
      const node = this.mapRef.current

      let zoom = this.state.zoom
      const { lat, lng } = this.state.currentLocation
      const center = new maps.LatLng(lat, lng)
      const cntx = this
      const map = this.map

      const mapConfig = Object.assign(
        {},
        {
          mapTypeControl: false,
          streetViewControl: false,
          center: center,
          zoom: zoom,
        },
      )
      // maps.Map() is constructor that instantiates the map
      this.map = new maps.Map(node, mapConfig)
      this.setState({ update: this.state.update + 1 })
    }
  }

  recenterMap() {
    const cntx = this
    const map = this.map
    const current = this.state.currentLocation
    const google = this.props.google
    google.maps.event.addListener(map, 'click', function (e) {
      var lat = e.latLng.lat()
      var lng = e.latLng.lng()
      var location = {
        lat,
        lng,
      }
      fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_GOOGLE_GEOCODING_KEY}`)
        .then(function (response) {
          return response.json()
        })
        .then(function (myJson) {
          cntx.setState({ currentLocation: location }, cntx.loadMap())
          cntx.props.updateLatLng(location.lat, location.lng)
          cntx.props.updateAdress(myJson.results[0].formatted_address)
        })
    })
    const maps = google.maps

    if (map) {
      let center = new maps.LatLng(current.lat, current.lng)
      map.panTo(center)
    }
  }

  renderChildren() {
    const { children } = this.props

    return React.Children.map(children, (c) => {
      let mark = React.cloneElement(c, {
        map: this.map,
        google: this.props.google,
        mapCenter: this.state.currentLocation,
      })
      return mark
    })
  }

  render() {
    const style = Object.assign({}, mapStyles.map)

    return (
      <div>
        <div style={style} ref={this.mapRef}>
          Loading map...
        </div>
        {this.renderChildren()}
      </div>
    )
  }
}
export default CurrentLocation

CurrentLocation.defaultProps = {
  initialCenter: {
    lng: -3.8196196,
    lat: 40.4381311,
  },
  centerAroundCurrentLocation: true,
  visible: true,
}
