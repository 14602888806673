import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import MobileStepper from '@material-ui/core/MobileStepper'
import { Grid } from '@material-ui/core'

const useStyles = makeStyles({
  root: {
    maxWidth: '100%',
    backgroundColor: '#fff',
  },
})

export default function ProgressMobileStepper(props) {
  const classes = useStyles()
  return (
    <Grid container direction="row" justify="center" alignItems="center">
      <Grid item>
        <MobileStepper variant="dots" steps={props.steps} position="static" activeStep={props.actualStep} className={classes.root} />
      </Grid>
    </Grid>
  )
}
