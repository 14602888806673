import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'

import DialogTitle from '@material-ui/core/DialogTitle'

export default function AlertDialog(props) {
  return (
    <div>
      <Dialog open={props.open} onClose={() => props.setOpen(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>

        <DialogActions>
          <Button onClick={() => props.setOpen(false)} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
