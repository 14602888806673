export const HTTPMethods = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE'
}

export const HTTPCodes = {
  OK: 200,
  Created: 201,
  Accepted: 202,
  NO_CONTENT: 204
}

export const apiService = (path, method = "GET", bodyObject = '') => {

  var url = path;
  let headers = new Headers();
  headers.append('Content-Type', 'application/json');

  switch (method) {
    case HTTPMethods.GET:
        return fetch(url, {
            headers: headers,
            method: method,
        });
    case HTTPMethods.POST:
        return fetch(url, {
            headers: headers,
            method: method,
            body: JSON.stringify(bodyObject)
        });
    case HTTPMethods.PUT:
        return fetch(url, {
            headers: headers,
            method: method,
            body: JSON.stringify(bodyObject)
        });
    case HTTPMethods.DELETE:
        return fetch(url, {
            headers: headers,
            method: method,
            body: JSON.stringify(bodyObject)
        });
    default:
        return new Promise((resolve,reject) => reject);
}

}
