import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import './i18n'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { reactAI } from 'react-appinsights'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'

let appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_INSTRUMENTATION_KEY,
    extensions: [reactAI],
    extensionConfig: {
      [reactAI.extensionId]: { debug: false },
    },
  },
})
appInsights.loadAppInsights()

ReactDOM.render(
  <React.StrictMode>
    <script type="text/javascript" src="https://maps.googleapis.com/maps/api/js?key=AIzaSyDbNAkdYHAodxmeRdR86XAK0S8q7gGz4VY&libraries=places"></script>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
