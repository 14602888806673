import React  from 'react'
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'


const useStyles = makeStyles({

    banderas: {
        width: '34px',
        height: '23px',
        backgroundImage: 'url(/banderas.png)',
        backgroundRepeat: 'no-repeat',
        display: 'inline-block',
        margin: '15px 5px 0 5px'
    },
    spain: {
        backgroundPosition: '0 -66px',
    },
    cat: {
        backgroundPosition: '0 -33px'
    },
    uk: {
        backgroundPosition: '0 0',
    }
})


const ChangeLanguage = props => {

    const [t, i18n] = useTranslation('global');
    const classes = useStyles();

    return (
        <div>
            <span className={`${classes.banderas} ${classes.spain}`} onClick={() => i18n.changeLanguage('es')}></span>
            <span className={`${classes.banderas} ${classes.cat}`} onClick={() => i18n.changeLanguage('cat')}></span>
            <span className={`${classes.banderas} ${classes.uk}`} onClick={() => i18n.changeLanguage('en')}></span>
        </div>
    )
}


ChangeLanguage.propTypes = {

}

export default ChangeLanguage
