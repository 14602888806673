import React, { Component } from 'react'
import { GoogleApiWrapper, InfoWindow, Marker } from 'google-maps-react'
import MyLocationIcon from '@material-ui/icons/MyLocation'
import CurrentLocation from './map'
import { Grid } from '@material-ui/core'
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import { withTheme } from '@material-ui/styles'

export class MapContainer extends Component {
  state = {
    activeMarker: {},
    selectedPlace: {},
    adress: '',
    latitude: null,
    value: '',
    longitude: null,
    manual: false,
  }

  onMarkerClick = (props, marker, e) =>
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
    })
  onClose = (props) => {
    this.setState({
      activeMarker: null,
    })
  }

  updateAdress = (adress) => {
    this.setState({ adress, value: '' })
    this.props.handleGeoPosition(adress, this.props.latitude, this.props.longitude)
  }
  updateLatLng = (latitude, longitude) => {
    this.setState({ latitude, longitude, zoom: 16 })
    this.props.handleGeoPosition(this.state.adress, latitude, longitude)
  }

  handleChange = (adress) => {
    this.setState({ adress, value: '' })
  }
  handleChangeInput = (event) => {
    this.setState({ value: event.target.value })
  }

  handleSelect = (adress) => {
    geocodeByAddress(adress)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        const { lat, lng } = latLng
        this.updateAdress(adress)
        this.updateLatLng(lat, lng)
      })
      .catch((error) => console.error('Error', error))
  }

  onClickLocation = () => {
    this.setState({ manual: true })
    // const cntx = this
    // navigator.geolocation.watchPosition(
    //   function (position) {
    //     navigator.geolocation.getCurrentPosition((pos, err) => {
    //       const coords = pos.coords
    //       cntx.updateLatLng(coords.latitude, coords.longitude)
    //     })
    //   },
    //   function (error) {
    //     if (error.code == error.PERMISSION_DENIED) console.log('you denied me :-(')
    //   },
    // )
  }

  render() {
    const { theme } = this.props
    if (this.state.adress && this.state.adress !== this.props.geoPosition) {
      this.props.handleGeoPosition(this.state.adress, this.state.latitude, this.state.longitude)
    }
    let map = <div />

    map = (
      <Grid item>
        <CurrentLocation
          centerAroundCurrentLocation
          google={this.props.google}
          updateAdress={this.updateAdress}
          updateLatLng={this.updateLatLng}
          adress={this.props.value}
          latitude={this.props.latitude}
          longitude={this.props.longitude}
          manual={this.props.manual}
        >
          <Marker onClick={this.onMarkerClick} name={'Posicion actual'} />
          <InfoWindow marker={this.state.activeMarker} onClose={this.onClose}>
            <div>
              <h4>{this.state.selectedPlace?.name + ':'}</h4>
              <span>{this.state.adress}</span>
            </div>
          </InfoWindow>
        </CurrentLocation>
      </Grid>
    )

    return (
      <Grid container direction="column" justify="center" spacing={4}>
        <Grid item>
          <PlacesAutocomplete value={this.state.adress} onChange={this.handleChange} onSelect={this.handleSelect}>
            {({ getInputProps, suggestions, getSuggestionItemProps }) => (
              <Grid container direction="row" justify="center" alignItems="center">
                <Grid icon xs={10}>
                  <input
                    value={this.state.value}
                    onClick={this.onClickLocation}
                    onChange={this.handleChangeInput}
                    className="inputGeo"
                    style={{
                      zIndex: 2,
                      fontSize: 16,
                      fontWeight: 400,
                      width: '105%',
                      padding: 16,
                      border: '2px solid ' + theme.palette.primary.main,
                      borderRadius: 3,
                    }}
                    className="input"
                    {...getInputProps({
                      placeholder: this.state.adress,
                      className: 'location-search-input',
                    })}
                  />
                </Grid>
                <Grid icon xs={2} style={{ zIndex: 4 }}>
                  <MyLocationIcon
                    style={{ backgroundColor: 'white', marginTop: 2, marginLeft: 7 }}
                    fontSize="large"
                    color="primary"
                    onClick={this.onClickLocation}
                  ></MyLocationIcon>
                </Grid>
                <div className="autocomplete-dropdown-container">
                  {suggestions.map((suggestion) => {
                    const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item'
                    // inline style for demonstration purpose
                    const style = suggestion.active ? { backgroundColor: '#fafafa', cursor: 'pointer' } : { backgroundColor: '#ffffff', cursor: 'pointer' }
                    return (
                      <div {...getSuggestionItemProps(suggestion, { className, style })}>
                        <span
                          style={{
                            width: '82%',
                          }}
                        >
                          {suggestion.description}
                        </span>
                        <hr></hr>
                      </div>
                    )
                  })}
                </div>
              </Grid>
            )}
          </PlacesAutocomplete>
        </Grid>
        {map}
      </Grid>
    )
  }
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
})(withTheme(MapContainer))
