import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

const resources = {
  es: {
    translation: {
      alert_geo: 'LA GEOLOCALIZACIÓN INTRODUCIDA ES INCORRECTA. POR FAVOR REVISE',
      error_send: 'La solucitud no se pudo realizar con éxito, póngase en contacto con la aseguradora.',
      baja_text: 'su solicitud se ha anulado con éxito.',
      '00000025': 'Reale',
      '00000016': 'Asitur',
      '00000002': 'Catalana Occidente',
      '00000001': 'Seguros Bilbao',
      '00000020': 'Plus Ultra',
      '00000007': 'Union Alcoyana',
      '00000010': 'MGS',
      '00000011': 'Helvetia',
      '00000006': 'GES',
      P: 'Carretera/Autovía',
      U: 'Centro urbano ',
      0: 'No arranca',
      1: 'Pinchazo de rueda/s',
      2: 'No Batería',
      3: 'Llaves dentro Vehículo',
      4: 'No Combustible',
      5: 'Otros',
      ubiTextHelp: 'Se recomienda activar la ubicación en el dispositivo, antes de continuar, para facilitar la búsqueda.',
      observationsOther: 'Observaciones otro tipo de avería',
      moreWheels: '¿Tiene recambio?',
      sume: 'el total de pasajeros no coincide con la selección de adultos y niños indicada',
      find_places: 'Buscar direcciones....',
      two_hours: 'Debe ser mínimo dos horas más que la actual y el formato hh:mm',
      no_se: 'NO SÉ',
      yes: 'SÍ',
      no: 'NO',
      other_moment: 'En otro momento',
      right_now: 'Ahora mismo',
      title_cancel_formulary_card: 'Anular solicitud',
      text_cancel_formulary_card: 'Confirme anulación de solicitud.',
      null_formulary: 'ANULAR SOLICITUD',
      cancel_formulary: 'CANCELAR SOLICITUD',
      error_link: 'El link ha expirado',
      incorrect_entry: 'Entrada con formato erróneo',
      incorrect_entry_plate: 'Formato correcto: 0000ABC ',
      incorrect_entry_dni: 'Formato correcto: DNI, NIF, NIE, Pasaporte o CIF  ',
      incorrect_entry_phone: 'Formato correcto: 9 DIGITOS ',
      incorrect_entry_mail: 'Formato correcto: ejemplo@mail.com ',
      required: 'Obligatorio',
      lightBatery: '¿Se encendía la luz roja de batería?',
      welcome: 'Bienvenido a su solicitud de asistencia digital en carretera',
      title_screen_1: 'DATOS PERSONALES',
      doc_identity: 'Doc. de identidad del tomador de la póliza',
      plate: 'Matrícula',
      next: 'Siguiente',
      wheelsCheckbox: 'Rueda/s pinchada/s',
      review: 'POR FAVOR, CONFIRME QUE TODOS LOS DATOS SEAN CORRECTOS',
      error_data:
        'No ha sido posible el envío de datos, en menos de cinco minutos, recibirá la llamada de uno de nuestros agentes para gestionar su solicitud, por favor espere a recibir la llamada',
      call: 'Llamar',
      title_screen_2: 'DATOS DE PÓLIZA',
      name: 'Nombre del tomador de la póliza',
      lastName: 'Apellidos del tomador de la póliza',
      name_tomador: 'Nombre',
      plate_2: 'Matrícula',
      title_screen_2_1: '¿Cuándo quiere que realicemos el servicio?',
      immediate: '¿Cuándo requiere el servicio de grúa?',
      day: 'Día',
      hour: 'Hora',
      title_legal: 'AVISO LEGAL',
      acept: 'Aceptar',
      legaltext_asitur:
        'AVISO LEGAL – PROTECCION DE DATOS –\nAutorización de alta de siniestro / parte de seguro: si usted no es el tomador y/ o asegurado en la póliza y actúa como un beneficiario de la misma, manifiesta disponer de legitimidad y autorización para dar de alta el siniestro.Protección de datos: le informamos que los datos serán tratados por ASITUR S.A., como Responsable del Tratamiento, con NIF A28749976 y domicilio social sito en Avenida de los Encuartes, 21, 28760, Tres Cantos, Madrid.Finalidad: es la tramitación y gestión del siniestro por usted comunicado.Base de legitimación: La base legal para el tratamiento de sus datos es la ejecución del contrato de seguro suscrito con la compañía aseguradora del vehículo.Categorías de datos: Las categorías de datos tratados serán los datos identificativos del tomador y en su caso de los beneficiarios de la póliza.En el caso de facilitar su geolocalizacion únicamente será tratada esta información a efectos de detectar su ubicación en el momento de solicitar el servicio.Puede ejercer los derechos que se le confieren tal y como se describe en la información adicional sobre protección de datos en https://www.asitur.es/aviso-legal/.',
      legaltext_reale:
        'AVISO LEGAL – PROTECCION DE DATOS –\nAutorización de alta de siniestro / parte de seguro: si usted no es el tomador y/o asegurado en la póliza y actúa como un beneficiario de la misma, manifiesta disponer de legitimidad y autorización para dar de alta el siniestro. Protección de datos: le informamos que los datos serán tratados por REALE SEGUROS GENERALES S.A., como Responsable del Tratamiento, con NIF A78520293 y domicilio social sito en Calle Príncipe de Vergara, 125, 28002, Madrid. Finalidad: es la tramitación y gestión del siniestro por usted comunicado. Base de legitimación: La base legal para el tratamiento de sus datos es la ejecución del contrato de seguro suscrito con la compañía aseguradora del vehículo. Categorías de datos: Las categorías de datos tratados serán los datos identificativos del tomador y en su caso de los beneficiarios de la póliza. En el caso de facilitar su geolocalizacion únicamente será tratada esta información a efectos de detectar su ubicación en el momento de solicitar el servicio. Puede ejercer los derechos que se le confieren tal y como se describe en la información adicional sobre protección de datos en https://www.reale.es/es/aviso-legal.',
      legaltext_catalana:
        'AVISO LEGAL – PROTECCION DE DATOS –\nAutorización de alta de siniestro / parte de seguro: si usted no es el tomador y/o asegurado en la póliza y actúa como un beneficiario de la misma, manifiesta disponer de legitimidad y autorización para dar de alta el siniestro. Protección de datos: le informamos que los datos serán tratados por Seguros Catalana Occidente S.A. de Seguros y Reaseguros., como Responsable del Tratamiento, con NIF A-28119220 y domicilio social sito en Paseo de la Castellana nº4 28046 (Madrid).  Finalidad: es la tramitación y gestión del siniestro por usted comunicado. Base de legitimación: La base legal para el tratamiento de sus datos es la ejecución del contrato de seguro suscrito con la compañía aseguradora del vehículo. Categorías de datos: Las categorías de datos tratados serán los datos identificativos del tomador y en su caso de los beneficiarios de la póliza. En el caso de facilitar su geolocalizacion únicamente será tratada esta información a efectos de detectar su ubicación en el momento de solicitar el servicio. Puede ejercer los derechos que se le confieren tal y como se describe en la información adicional sobre protección de datos y política de privacidad publicado en  www.grupocatalanaoccidente.com',
      legal_text_SegurosBilbao:
        'AVISO LEGAL – PROTECCION DE DATOS –\nAutorización de alta de siniestro / parte de seguro: si usted no es el tomador y/ o asegurado en la póliza y actúa como un beneficiario de la misma, manifiesta disponer de legitimidad y autorización para dar de alta el siniestro. Protección de datos: le informamos que los datos serán tratados por  Bilbao, Compañía Anónima de Seguros y Reaseguros., como Responsable del Tratamiento, con NIF A-48001648 y domicilio social sito en Paseo del Puerto 20, 48992 Neguri-Getxo (Vizcaya). Finalidad: es la tramitación y gestión del siniestro por usted comunicado. Base de legitimación: La base legal para el tratamiento de sus datos es la ejecución del contrato de seguro suscrito con la compañía aseguradora del vehículo. Categorías de datos: Las categorías de datos tratados serán los datos identificativos del tomador y en su caso de los beneficiarios de la póliza. En el caso de facilitar su geolocalización únicamente será tratada esta información a efectos de detectar su ubicación en el momento de solicitar el servicio. Puede ejercer los derechos que se le confieren tal y como se describe en la información adicional sobre protección de datos en https://www.segurosbilbao.com/aviso-legal',
      legal_text_PlusUltra:
        'AVISO LEGAL – PROTECCION DE DATOS –\nAutorización de alta de siniestro / parte de seguro: si usted no es el tomador y/ o asegurado en la póliza y actúa como un beneficiario de la misma, manifiesta disponer de legitimidad y autorización para dar de alta el siniestro. Protección de datos: le informamos que los datos serán tratados por  Plus Ultra Seguros Generales y Vida, S.A. de Seguros y Reaseguros, Sociedad Unipersonal., como Responsable del Tratamiento, con NIF A-30014831 y domicilio social sito en Plaza de las Cortes 8,  28014 Madrid (España). Finalidad: es la tramitación y gestión del siniestro por usted comunicado. Base de legitimación: La base legal para el tratamiento de sus datos es la ejecución del contrato de seguro suscrito con la compañía aseguradora del vehículo. Categorías de datos: Las categorías de datos tratados serán los datos identificativos del tomador y en su caso de los beneficiarios de la póliza. En el caso de facilitar su geolocalización únicamente será tratada esta información a efectos de detectar su ubicación en el momento de solicitar el servicio. Puede ejercer los derechos que se le confieren tal y como se describe en la información adicional sobre protección de datos en https://www.plusultra.es/aviso-legal',
      legal_text_Helvetia:
        'AVISO LEGAL – PROTECCION DE DATOS –\nAutorización de alta de siniestro / parte de seguro: si usted no es el tomador y/ o asegurado en la póliza y actúa como un beneficiario de la misma, manifiesta disponer de legitimidad y autorización para dar de alta el siniestro. Protección de datos: le informamos que los datos serán tratados por Helvetia Compañía Suiza, Sociedad Anónima de Seguros y Reaseguros., como Responsable del Tratamiento, con NIF A41003864 y domicilio social sito en calle Paseo de Cristóbal Colón, número 26, 41001 Sevilla, España. Finalidad: es la tramitación y gestión del siniestro por usted comunicado. Base de legitimación: La base legal para el tratamiento de sus datos es la ejecución del contrato de seguro suscrito con la compañía aseguradora del vehículo. Categorías de datos: Las categorías de datos tratados serán los datos identificativos del tomador y en su caso de los beneficiarios de la póliza. En el caso de facilitar su geolocalización únicamente será tratada esta información a efectos de detectar su ubicación en el momento de solicitar el servicio. Puede ejercer los derechos que se le confieren tal y como se describe en la información adicional sobre protección de datos en https://www.helvetia.es/aviso-legal',
      legal_text_GES:
        'AVISO LEGAL – PROTECCION DE DATOS –\nAutorización de alta de siniestro / parte de seguro: si usted no es el tomador y/ o asegurado en la póliza y actúa como un beneficiario de la misma, manifiesta disponer de legitimidad y autorización para dar de alta el siniestro. Protección de datos: le informamos que los datos serán tratados por GES SEGUROS Y REASEGUROS S.A., como Responsable del Tratamiento, con NIF  A-28008712 y domicilio social sito en Madrid 28014, Plaza de las Cortes nº 2 . Finalidad: es la tramitación y gestión del siniestro por usted comunicado. Base de legitimación: La base legal para el tratamiento de sus datos es la ejecución del contrato de seguro suscrito con la compañía aseguradora del vehículo. Categorías de datos: Las categorías de datos tratados serán los datos identificativos del tomador y en su caso de los beneficiarios de la póliza. En el caso de facilitar su geolocalización únicamente será tratada esta información a efectos de detectar su ubicación en el momento de solicitar el servicio. Puede ejercer los derechos que se le confieren tal y como se describe en la información adicional sobre protección de datos en https://bienvenido.ges.es/aviso-legal',
      legal_text_MGS:
        'AVISO LEGAL – PROTECCION DE DATOS –\nAutorización de alta de siniestro / parte de seguro: si usted no es el tomador y/ o asegurado en la póliza y actúa como un beneficiario de la misma, manifiesta disponer de legitimidad y autorización para dar de alta el siniestro. Protección de datos: le informamos que los datos serán tratados por MGS, Seguros y Reaseguros S.A., como Responsable del Tratamiento, con NIF A-08171373 y domicilio social sito en Zaragoza, Paseo María Agustín, nº 4, 50004. Finalidad: es la tramitación y gestión del siniestro por usted comunicado. Base de legitimación: La base legal para el tratamiento de sus datos es la ejecución del contrato de seguro suscrito con la compañía aseguradora del vehículo. Categorías de datos: Las categorías de datos tratados serán los datos identificativos del tomador y en su caso de los beneficiarios de la póliza. En el caso de facilitar su geolocalización únicamente será tratada esta información a efectos de detectar su ubicación en el momento de solicitar el servicio. Puede ejercer los derechos que se le confieren tal y como se describe en la información adicional sobre protección de datos en https://www.mgs.es/wps/portal/publica/avisolegal',
      legal_text_UnionAlcoyana:
        'AVISO LEGAL – PROTECCION DE DATOS –\nAutorización de alta de siniestro / parte de seguro: si usted no es el tomador y/ o asegurado en la póliza y actúa como un beneficiario de la misma, manifiesta disponer de legitimidad y autorización para dar de alta el siniestro. Protección de datos: le informamos que los datos serán tratados por Unión Alcoyana, Sociedad Anónima de Seguros y Reaseguros., como Responsable del Tratamiento, con NIF A-03007770 y domicilio social sito en Calle Gonzalo Barrachina, 4 – 03801 ALCOY (Alicante). Finalidad: es la tramitación y gestión del siniestro por usted comunicado. Base de legitimación: La base legal para el tratamiento de sus datos es la ejecución del contrato de seguro suscrito con la compañía aseguradora del vehículo. Categorías de datos: Las categorías de datos tratados serán los datos identificativos del tomador y en su caso de los beneficiarios de la póliza. En el caso de facilitar su geolocalización únicamente será tratada esta información a efectos de detectar su ubicación en el momento de solicitar el servicio. Puede ejercer los derechos que se le confieren tal y como se describe en la información adicional sobre protección de datos en https://unionalcoyana.com/aviso-legal-union-alcoyana-seguros/',
      tlf_reale: '910920263',
      tlf_catalana: '932220212',
      tlf_PlusUltra: '917838383',
      tlf_SegurosBilbao: '946421241',
      tlf_Helvetia: '902120927',
      tlf_GES: '913308585',
      tlf_MGS: '902120863',
      tlf_UnionAlcoyana: '800888888',
      tlf_Asitur: '',
      title_screen_3: 'AVERÍA DEL VEHÍCULO',
      wheels: 'Rueda/s pinchada/s',
      batery: 'No tengo batería',
      keys: 'Llaves dentro del vehículo',
      fuel: 'No tengo combustible',
      other: 'Otras averías',
      observations: 'Observaciones',
      title_screen_4: 'Indíquenos en dónde está el vehículo',
      title_screen_4_1: 'UBICACIÓN DE VEHÍCULO',
      confirmed: 'CONFIRMAR SOLICITUD',
      noStart: 'No arranca',
      ubi: 'Dirección',
      ubi_body: 'Lugar de recogida',
      highway: 'Carretera/Autovía',
      city: 'Centro urbano ',
      parking: 'Vehículo en Parking subterráneo',
      title_screen_5: 'INFORMACIÓN ADICIONAL',
      title_screen_5_1: 'DATOS DE OCUPANTES',
      title_screen_5_2: 'OBSERVACIÓN ADICIONAL QUE CONSIDERE IMPORTANTE PARA LA REALIZACIÓN DE SU ASISTENCIA',
      number_persons_ask: '¿Cuántas personas viajan en el vehículo?',
      adults: 'Adultos',
      boys: 'Niños',
      special_needs: '¿Alguno con necesidades especiales?',
      title_screen_6: 'RESUMEN DE SU SOLICITUD',
      type_fault: 'Tipo de avería',
      number_persons: 'Número de personas',
      type_ubi: 'Tipo de Vía',
      parking_subway: 'Parking subterráneo',
      title_screen_7: 'Su petición de asistencia se ha registrado correctamente',
      end_text: 'Gracias por usar el servicio.',
      rquired_checkbox: 'Se debe seleccionar una avería',
      exp_number: ' Su expediente es ',
      colab_name: ', atenderá su solicitud ',
      tft_text: 'Puede ponerse en contacto con nosotros en este número de teléfono',
      attend_text: 'atenderá su solicitud',
      alta_no_ok: 'En breves instantes nos pondremos en contacto con usted.',
      grua_no_ok: 'La grúa va en camino.',
      alert_geo: 'LA GEOLOCALIZACIÓN INTRODUCIDA ES INCORRECTA. POR FAVOR REVISE',
    },
  },
  en: {
    translation: {
      alert_geo: 'The geolocation input is incorrect. Please check',
      error_send: 'The application could not be performed successfully. Please contact your insurance company.',
      baja_text: 'your request has been successfully canceled.',
      '00000025': 'Reale',
      '00000016': 'Asitur',
      '00000002': 'Catalana Occidente',
      '00000001': 'Seguros Bilbao',
      '00000020': 'Plus Ultra',
      '00000007': 'Union Alcoyana',
      '00000010': 'MGS',
      '00000011': 'Helvetia',
      '00000006': 'GES',
      P: 'Road/Dual carriageway',
      U: 'City centre',
      0: 'Does not start',
      1: 'Tyre/s punctured',
      2: 'Battery flat',
      3: 'Keys inside the vehicle',
      4: 'No fuel',
      5: 'Others',
      ubiTextHelp: 'We recommend activating the location on the device before continuing to facilitate the search.',
      observationsOther: 'Remarks on other kind of failure',
      moreWheels: 'Do you have a spare?',
      sume: 'The total number of passengers does not match the selection of adults and children stated',
      find_places: 'Search for addresses....',
      two_hours: 'Must be at least two hours more than the present time and in hh:mm format',
      no_se: 'I do not know',
      yes: 'Yes',
      no: 'No',
      other_moment: 'At another time',
      right_now: 'Right now',
      title_cancel_formulary_card: 'Cancel request',
      text_cancel_formulary_card: 'Confirm cancellation of request.',
      null_formulary: 'ANULAR SOLICITUD',
      cancel_formulary: 'Cancel form',
      error_link: 'The link has expired',
      incorrect_entry: 'Input with erroneous format',
      incorrect_entry_plate: 'Correct format: 0000ABC',
      incorrect_entry_dni: 'Correct format: Id. Card, T.I.N., Passport or Co. Tax No.',
      incorrect_entry_phone: 'Correct format: 9 DIGITS',
      incorrect_entry_mail: 'Correct format: example@mail.com',
      required: 'Obligatory',
      lightBatery: 'Battery red light is on',
      welcome: 'Welcome to your request for digital roadside assitance',
      title_screen_1: 'PERSONAL DATA',
      doc_identity: 'Identity document of the policy holder',
      plate: 'Licence plate number',
      next: 'Next',
      wheelsCheckbox: 'Tyre/s punctured',
      review: 'Please confirm that all the data is correct',
      error_data:
        'It has not been possible to send data. You will receive a call from one of our agents within less than five minutes to process your application. Please wait to receive the call.',
      call: 'Call',
      title_screen_2: 'DATOS DE PÓLIZA',
      name: 'Name of the policy taker',
      lastName: 'Surname of the policy taker',
      name_tomador: 'Name',
      plate_2: 'Licence plate number',
      title_screen_2_1: 'When do you want us to provide the service?',
      immediate: 'When do you need the tow service?',
      day: 'Day',
      hour: 'Time',
      title_legal: 'Legal Notice',
      acept: 'Accept',
      legaltext_asitur:
        'LEGAL NOTICE. DATA PROTECTION Claim registration authorisation / insurance report: if you are not the taker and/or insured of the policy and are acting as its beneficiary, you declare you have legal authorisation to register the claim. Data protection: we inform you that your data shall be processed by ASITUR S.A., as Data Controller, with T.I.N. A28749976 and registered office located at Avenida de los Encuartes, 21, 28760, Tres Cantos, Madrid. Purpose: this is to process and manage the claim you notify. Authority based on: The legal basis for processing your data is performance of the insurance contract signed with the company that insures the vehicle. Data categories: The categories of data processed shall be the data identifying the taker and if appropriate the beneficiaries of the policy. If you provide your geolocation, that information shall only be processed in order to detect your location at the moment of requesting the service. You may exercise the rights you are granted as described in the additional information on data protection at https://www.asitur.es/aviso-legal/.',
      legaltext_reale:
        'LEGAL NOTICE. DATA PROTECTION Claim registration authorisation / insurance report: if you are not the taker and/or insured of the policy and are acting as its beneficiary, you declare you have legal authorisation to register the claim. Data protection: we inform you that your data shall be processed by REALE SEGUROS GENERALES S.A., as Data Controller, with T.I.N. A78520293 and registered office located at Calle Principe de Vergara, 125, 28002, Madrid. Purpose: this is to process and manage the claim you notify. Authority based on: The legal basis for processing your data is performance of the insurance contract signed with the company that insures the vehicle. Data categories: The categories of data processed shall be the data identifying the taker and if appropriate the beneficiaries of the policy. If you provide your geolocation, that information shall only be processed in order to detect your location at the moment of requesting the service. You may exercise the rights you are granted as described in the additional information on data protection at https://www.reale.es/es/aviso-legal.',
      legaltext_catalana:
        'LEGAL NOTICE. DATA PROTECTION Claim registration authorisation / insurance report: if you are not the taker and/or insured of the policy and are acting as its beneficiary, you declare you have legal authorisation to register the claim. Data protection: we inform you that your data shall be processed by Seguros Catalana Occidente S.A. de Seguros y Reaseguros., as Data Controller, with T.I.N. A-28119220 and registered office located at Paseo de la Castellana nº4 28046 (Madrid).  Purpose: this is to process and manage the claim you notify. Authority based on: The legal basis for processing your data is performance of the insurance contract signed with the company that insures the vehicle. Data categories: The categories of data processed shall be the data identifying the taker and if appropriate the beneficiaries of the policy. If you provide your geolocation, that information shall only be processed in order to detect your location at the moment of requesting the service. You may exercise the rights as described in the additional information on data and privacy policy published at www.grupocatalanaoccidente.com',
      legal_text_SegurosBilbao:
        'LEGAL NOTICE. DATA PROTECTION Claim registration authorisation / insurance report: if you are not the taker and/or insured of the policy and are acting as its beneficiary, you declare you have legal authorisation to register the claim. Data protection: we inform you that your data shall be processed by Bilbao, Compañía Anónima de Seguros y Reaseguros., as Data Controller, with T.I.N. A-48001648 and registered office located at Paseo del Puerto 20, 48992 Neguri-Getxo (Vizcaya). Purpose: this is to process and manage the claim you notify. Authority based on: The legal basis for processing your data is performance of the insurance contract subscribed with the company insuring the vehicle. Data categories: The categories of data processed shall be the data identifying the taker and if appropriate the beneficiaries of the policy. If you provide your geolocation, that information shall only be processed in order to detect your location at the moment of requesting the service. You may exercise the rights as described in the additional information on data and privacy policy published at https://www.segurosbilbao.com/aviso-legal',
      legal_text_PlusUltra:
        'LEGAL NOTICE. DATA PROTECTION Claim registration authorisation / insurance report: if you are not the taker and/or insured of the policy and are acting as its beneficiary, you declare you have legal authorisation to register the claim. Data protection: we inform you that your data shall be processed by ASITUR S.A., as Data Controller, with T.I.N. A28749976 and registered office located at Avenida de los Encuartes, 21, 28760, Tres Cantos, Madrid. Purpose: this is to process and manage the claim you notify. Authority based on: The legal basis for processing your data is performance of the insurance contract signed with the company that insures the vehicle. Data categories: The categories of data processed shall be the data identifying the taker and if appropriate the beneficiaries of the policy. If you provide your geolocation, that information shall only be processed in order to detect your location at the moment of requesting the service. You may exercise the rights you are granted as described in the additional information on data protection at https://www.asitur.es/aviso-legal/.',
      legal_text_Helvetia:
        'LEGAL NOTICE. DATA PROTECTION Claim registration authorisation / insurance report: if you are not the taker and/or insured of the policy and are acting as its beneficiary, you declare you have legal authorisation to register the claim. Data protection: we inform you that your data shall be processed by ASITUR S.A., as Data Controller, with T.I.N. A28749976 and registered office located at Avenida de los Encuartes, 21, 28760, Tres Cantos, Madrid. Purpose: this is to process and manage the claim you notify. Authority based on: The legal basis for processing your data is performance of the insurance contract signed with the company that insures the vehicle. Data categories: The categories of data processed shall be the data identifying the taker and if appropriate the beneficiaries of the policy. If you provide your geolocation, that information shall only be processed in order to detect your location at the moment of requesting the service. You may exercise the rights you are granted as described in the additional information on data protection at https://www.asitur.es/aviso-legal/.',
      legal_text_GES:
        'LEGAL NOTICE. DATA PROTECTION Claim registration authorisation / insurance report: if you are not the taker and/or insured of the policy and are acting as its beneficiary, you declare you have legal authorisation to register the claim. Data protection: we inform you that your data shall be processed by ASITUR S.A., as Data Controller, with T.I.N. A28749976 and registered office located at Avenida de los Encuartes, 21, 28760, Tres Cantos, Madrid. Purpose: this is to process and manage the claim you notify. Authority based on: The legal basis for processing your data is performance of the insurance contract signed with the company that insures the vehicle. Data categories: The categories of data processed shall be the data identifying the taker and if appropriate the beneficiaries of the policy. If you provide your geolocation, that information shall only be processed in order to detect your location at the moment of requesting the service. You may exercise the rights you are granted as described in the additional information on data protection at https://www.asitur.es/aviso-legal/.',
      legal_text_MGS:
        'LEGAL NOTICE. DATA PROTECTION Claim registration authorisation / insurance report: if you are not the taker and/or insured of the policy and are acting as its beneficiary, you declare you have legal authorisation to register the claim. Data protection: we inform you that your data shall be processed by ASITUR S.A., as Data Controller, with T.I.N. A28749976 and registered office located at Avenida de los Encuartes, 21, 28760, Tres Cantos, Madrid. Purpose: this is to process and manage the claim you notify. Authority based on: The legal basis for processing your data is performance of the insurance contract signed with the company that insures the vehicle. Data categories: The categories of data processed shall be the data identifying the taker and if appropriate the beneficiaries of the policy. If you provide your geolocation, that information shall only be processed in order to detect your location at the moment of requesting the service. You may exercise the rights you are granted as described in the additional information on data protection at https://www.asitur.es/aviso-legal/.',
      legal_text_UnionAlcoyana:
        'LEGAL NOTICE. DATA PROTECTION Claim registration authorisation / insurance report: if you are not the taker and/or insured of the policy and are acting as its beneficiary, you declare you have legal authorisation to register the claim. Data protection: we inform you that your data shall be processed by ASITUR S.A., as Data Controller, with T.I.N. A28749976 and registered office located at Avenida de los Encuartes, 21, 28760, Tres Cantos, Madrid. Purpose: this is to process and manage the claim you notify. Authority based on: The legal basis for processing your data is performance of the insurance contract signed with the company that insures the vehicle. Data categories: The categories of data processed shall be the data identifying the taker and if appropriate the beneficiaries of the policy. If you provide your geolocation, that information shall only be processed in order to detect your location at the moment of requesting the service. You may exercise the rights you are granted as described in the additional information on data protection at https://www.asitur.es/aviso-legal/.',
      tlf_reale: '910920263',
      tlf_catalana: '932220212',
      tlf_PlusUltra: '917838383',
      tlf_SegurosBilbao: '946421241',
      tlf_Helvetia: '902120927',
      tlf_GES: '913308585',
      tlf_MGS: '902120863',
      tlf_UnionAlcoyana: '800888888',
      tlf_Asitur: '',
      title_screen_3: 'Vehicle failure',
      wheels: 'Tyre/s punctured',
      batery: 'The battery is flat',
      keys: 'Keys inside the vehicle',
      fuel: 'I have no fuel',
      other: 'Other failures',
      observations: 'Remarks',
      title_screen_4: 'State where the vehicle is',
      title_screen_4_1: 'Vehicle location',
      confirmed: 'Confirm application',
      noStart: 'It does not start',
      ubi: 'Address',
      ubi_body: 'Place for collection',
      highway: 'Road/Dual carriageway',
      city: 'City centre',
      parking: 'Vehicle in underground carpark',
      title_screen_5: 'ADDITIONAL INFORMATION',
      title_screen_5_1: 'DATA OF THE OCCUPANTS',
      title_screen_5_2: 'ADDITIONAL INFORMATION FOR ASSISTANCE THAT IS CONSIDERED NECESSARY',
      number_persons_ask: 'How many people are travelling in the vehicle?',
      adults: 'Adults',
      boys: 'Children',
      special_needs: 'Any with special needs?',
      title_screen_6: 'APPLICATION SUMMARY',
      type_fault: 'Type of failure',
      number_persons: 'Number of people',
      type_ubi: 'Type of road',
      parking_subway: 'Underground carpark',
      title_screen_7: 'Your request for assistance has been recorded correctly',
      end_text: 'Thank you for using the service.',
      rquired_checkbox: 'You must select a failure',
      exp_number: ' Your file is ',
      colab_name: ', will attend to your application ',
      alert_geo: 'The geolocation input is incorrect. Please check.',
      attend_text: ' will attend your request',
      alta_no_ok: 'You will shortly receive a call from one of our agents to finalize your request',
      grua_no_ok: 'your tow truck is on its way.',
      tft_text: 'You can contact us in this phone number',
    },
  },
  cat: {
    translation: {
      alert_geo: 'La geolocalització introduïda és incorrecta. Si us plau revisi',
      error_send: 'La sol·licitud no es va poder realitzar amb èxit.',
      baja_text: "seva sol·licitud s'ha anul·lat amb èxit",
      '00000025': 'Reale',
      '00000016': 'Asitur',
      '00000002': 'Catalana Occidente',
      '00000001': 'Seguros Bilbao',
      '00000020': 'Plus Ultra',
      '00000007': 'Union Alcoyana',
      '00000010': 'MGS',
      '00000011': 'Helvetia',
      '00000006': 'GES',
      P: 'Carretera/Autovia',
      U: 'Centre urbà',
      0: 'No arranca',
      1: 'Punxada de rodes',
      2: 'No Batería',
      3: 'Claus dintre del vehicle',
      4: 'No Combustible',
      5: 'Altres',
      ubiTextHelp: 'Es recomana activar la ubicació en el dispositiu abans de continuar per facilitar la recerca.',
      observationsOther: "Observacions altre tipus d'avaria",
      moreWheels: 'Té recanvi?',
      sume: "El total de passatgers no coincideix amb la selecció d'adults i nens indicada",
      find_places: 'Buscar direccions....',
      two_hours: "Ha de ser un mínim de dues hores més que l'actual i el format hh:mm",
      no_se: 'No ho sé',
      yes: 'Si',
      no: 'No',
      other_moment: 'En un altre moment',
      right_now: 'Ara mateix',
      title_cancel_formulary_card: 'Anul·lar sol·licitud',
      text_cancel_formulary_card: 'Confirmeu anul·lació de sol·licitud.',
      null_formulary: 'ANULAR SOLICITUD',
      cancel_formulary: 'Cancel·la sol·licitud',
      error_link: 'El link ha exhaurit',
      incorrect_entry: 'Entrada amb format erroni',
      incorrect_entry_plate: 'Format correcte: 0000ABC ',
      incorrect_entry_dni: 'Format correcte: DNI, NIF, NIE, Passaport o CIF',
      incorrect_entry_phone: 'Format correcte: 9 dígits',
      incorrect_entry_mail: 'Format correcte: exemple@mail.com',
      required: 'Obligatori',
      lightBatery: "S'encenia la llum vermella de bateria?",
      welcome: "Benvingut a la seva sol·licitud d'assistència digital a la carretera",
      title_screen_1: 'DADES PERSONALS',
      doc_identity: "Document d'identitat del prenedor de la pòlissa",
      plate: 'Matrícula',
      next: 'Següent',
      wheelsCheckbox: 'Roda/es punxada/es',
      review: 'Si us plau, confirmi que totes les dades con correctes',
      error_data:
        'No ha estat possible lenviament de dades, en menys de cinc minuts, rebrà la trucada dun dels nostres agents per gestionar la seva sol·licitud, si us plau, esperi a rebre la trucada',
      call: 'Trucar',
      title_screen_2: 'DATOS DE PÓLIZA',
      name: 'Nom del prenedor de la pòlissa',
      lastName: 'Cognoms del prenedor de la pòlissa',
      name_tomador: 'Nom',
      plate_2: 'Matrícula',
      title_screen_2_1: 'Quan vol que realitzem el servei?',
      immediate: 'Quan requereix el servei de grua?',
      day: 'Día',
      hour: 'Hora',
      title_legal: 'Avís Legal',
      acept: 'Acceptar',
      legaltext_asitur:
        "AVÍS LEGAL  PROTECCIÓ DE DADES Autorització d'alta de sinistre / part d'assegurança: si vostè no és el prenedor i/ o assegurat en la pòlissa i actua com un beneficiari de la mateixa manifesta disposar de legitimitat i autorització per donar d'alta el sinistre. Protecció de dades: li informem que les dades seran tractades per ASITUR S.A., com Responsable del Tractament, amb NIF A28749976 i domicili social situat a Avinguda de los Encuartes, 21, 28760, Tres Cantos, Madrid. Finalitat: és la tramitació i gestió del sinistre per vostè comunicat. Base de legitimació: La base legal per al tractament de les seves dades és l'execució del contracte dassegurança.",
      legaltext_reale:
        "AVÍS LEGAL  PROTECCIÓ DE DADESAutorització d'alta de sinistre/ part d'assegurança: si vostè no és el prenedor i/o assegurat a la pòlissa i actua com un beneficiari de la mateixa, manifesta disposar de legitimitat i autorització per donar d'alta el sinistre. Protecció de dades: li informem que les dades seran tractades per REALE SEGUROS GENERALES S.A., com a Responsable del Tractament, amb NIF A78520293 i domicili social a Carrer Príncipe de Vergara, 125, 28002, Madrid. Finalitat: és la tramitació i gestió del sinistre per vostè comunicat. Base de legitimació: La base legal per al tractament de les seves dades és l'execució del contracte dassegurança subscrit amb la companyia asseguradora del vehicle. Categories de dades: Les categories de dades tractades seran les dades identificatives del prenedor i en el seu cas dels beneficiaris de la pòlissa. En el cas de facilitar la seva geolocalització únicament serà tractada aquesta informació a efectes de detectar la seva ubicació en el moment de sol·licitar el servei.",
      legaltext_catalana:
        "AVÍS LEGAL  PROTECCIÓ DE DADESAutorització d'alta de sinistre/ part d'assegurança: si vostè no és el prenedor i/o assegurat a la pòlissa i actua com un beneficiari de la mateixa, manifesta disposar de legitimitat i autorització per donar d'alta el sinistre. Protecció de dades: li informem que les dades seran tractades per Seguros Catalana Occidente S.A. de Seguros y Reaseguros., com Responsable del Tractament, amb NIF A-28119220 i domicili social situat a Passeig de la Castellana nº4 28046 (Madrid).  Finalitat: és la tramitació i gestió del sinistre por vostè comunicat. Base de legitimació: La base legal per al tractament de les seves dades és l'execució del contracte dassegurança subscrit amb la companyia asseguradora del vehicle. Categories de dades: Les categories de dades tractades seran les dades identificatives del prenedor i en el seu cas dels beneficiaris de la pòlissa. En el cas de facilitar la seva geolocalizació únicament serà  tractada aquesta informació a efectes de detectar la seva ubicació en el moment de sol·licitar el servei. Pot exercir els drets que se li confereixen tal i com es descriu a la informació addicional sobre protecció de dades i política de privacitat publicat a www.grupocatalanaoccidente.com",
      legal_text_SegurosBilbao:
        "AVÍS LEGAL  PROTECCIÓ DE DADES Autorització d'alta de sinistre / part d'assegurança: si vostè no és el prenedor i/ o assegurat en la pòlissa i actua com un beneficiari de la mateixa manifesta disposar de legitimitat i autorització per donar d'alta el sinistre. Protecció de dades: li informem que les dades seran tractades per Bilbao, Compañía Anónima de Seguros y Reaseguros., com Responsable del Tractament, amb NIF A-48001648 i domicili social situat a Passeig del Puerto 20, 48992 Neguri-Getxo (Vizcaya). Finalitat: és la tramitació i gestió del sinistre per vostè comunicat. Base de legitimació: La base legal per al tractament de les seves dades és l'execució del contracte dassegurança subscrit amb la companyia asseguradora del vehicle. Categories de dades: Les categories de dades tractades seran les dades identificatives del prenedor i en el seu cas dels beneficiaris de la pòlissa. En el cas de facilitar la seva geolocalizació únicament serà tractada aquesta informació a efectes de detectar la seva ubicació en el moment de sol·licitar el servei. Pot exercir els drets que se li confereixen tal i com se descriu a la informació addicional sobre protecció de dades a https://www.segurosbilbao.com/aviso-legal",
      legal_text_PlusUltra:
        "AVÍS LEGAL  PROTECCIÓ DE DADES Autorització d'alta de sinistre / part d'assegurança: si vostè no és el prenedor i/ o assegurat en la pòlissa i actua com un beneficiari de la mateixa manifesta disposar de legitimitat i autorització per donar d'alta el sinistre. Protecció de dades: li informem que les dades seran tractades per ASITUR S.A., com Responsable del Tractament, amb NIF A28749976 i domicili social situat a Avinguda de los Encuartes, 21, 28760, Tres Cantos, Madrid. Finalitat: és la tramitació i gestió del sinistre per vostè comunicat. Base de legitimació: La base legal per al tractament de les seves dades és l'execució del contracte dassegurança subscrit amb la companyia asseguradora del vehicle. Categories de dades: Les categories de dades tractades seran les dades identificatives del prenedor i en el seu cas dels beneficiaris de la pòlissa. En el cas de facilitar la seva geolocalizació únicament serà  tractada aquesta informació a efectes de detectar la seva ubicació en el moment de sol·licitar el servei. Pot exercir els drets que se li confereixen tal i com es descriu a la informació addicional sobre protecció de dades a  https://www.asitur.es/aviso-legal/.",
      legal_text_Helvetia:
        "AVÍS LEGAL  PROTECCIÓ DE DADES Autorització d'alta de sinistre / part d'assegurança: si vostè no és el prenedor i/ o assegurat en la pòlissa i actua com un beneficiari de la mateixa manifesta disposar de legitimitat i autorització per donar d'alta el sinistre. Protecció de dades: li informem que les dades seran tractades per ASITUR S.A., com Responsable del Tractament, amb NIF A28749976 i domicili social situat a Avinguda de los Encuartes, 21, 28760, Tres Cantos, Madrid. Finalitat: és la tramitació i gestió del sinistre per vostè comunicat. Base de legitimació: La base legal per al tractament de les seves dades és l'execució del contracte dassegurança subscrit amb la companyia asseguradora del vehicle. Categories de dades: Les categories de dades tractades seran les dades identificatives del prenedor i en el seu cas dels beneficiaris de la pòlissa. En el cas de facilitar la seva geolocalizació únicament serà  tractada aquesta informació a efectes de detectar la seva ubicació en el moment de sol·licitar el servei. Pot exercir els drets que se li confereixen tal i com es descriu a la informació addicional sobre protecció de dades a  https://www.asitur.es/aviso-legal/.",
      legal_text_GES:
        "AVÍS LEGAL  PROTECCIÓ DE DADES Autorització d'alta de sinistre / part d'assegurança: si vostè no és el prenedor i/ o assegurat en la pòlissa i actua com un beneficiari de la mateixa manifesta disposar de legitimitat i autorització per donar d'alta el sinistre. Protecció de dades: li informem que les dades seran tractades per ASITUR S.A., com Responsable del Tractament, amb NIF A28749976 i domicili social situat a Avinguda de los Encuartes, 21, 28760, Tres Cantos, Madrid. Finalitat: és la tramitació i gestió del sinistre per vostè comunicat. Base de legitimació: La base legal per al tractament de les seves dades és l'execució del contracte dassegurança subscrit amb la companyia asseguradora del vehicle. Categories de dades: Les categories de dades tractades seran les dades identificatives del prenedor i en el seu cas dels beneficiaris de la pòlissa. En el cas de facilitar la seva geolocalizació únicament serà  tractada aquesta informació a efectes de detectar la seva ubicació en el moment de sol·licitar el servei. Pot exercir els drets que se li confereixen tal i com es descriu a la informació addicional sobre protecció de dades a  https://www.asitur.es/aviso-legal/.",
      legal_text_MGS:
        "AVÍS LEGAL  PROTECCIÓ DE DADES Autorització d'alta de sinistre / part d'assegurança: si vostè no és el prenedor i/ o assegurat en la pòlissa i actua com un beneficiari de la mateixa manifesta disposar de legitimitat i autorització per donar d'alta el sinistre. Protecció de dades: li informem que les dades seran tractades per ASITUR S.A., com Responsable del Tractament, amb NIF A28749976 i domicili social situat a Avinguda de los Encuartes, 21, 28760, Tres Cantos, Madrid. Finalitat: és la tramitació i gestió del sinistre per vostè comunicat. Base de legitimació: La base legal per al tractament de les seves dades és l'execució del contracte dassegurança subscrit amb la companyia asseguradora del vehicle. Categories de dades: Les categories de dades tractades seran les dades identificatives del prenedor i en el seu cas dels beneficiaris de la pòlissa. En el cas de facilitar la seva geolocalizació únicament serà  tractada aquesta informació a efectes de detectar la seva ubicació en el moment de sol·licitar el servei. Pot exercir els drets que se li confereixen tal i com es descriu a la informació addicional sobre protecció de dades a  https://www.asitur.es/aviso-legal/.",
      legal_text_UnionAlcoyana:
        "AVÍS LEGAL  PROTECCIÓ DE DADES Autorització d'alta de sinistre / part d'assegurança: si vostè no és el prenedor i/ o assegurat en la pòlissa i actua com un beneficiari de la mateixa manifesta disposar de legitimitat i autorització per donar d'  alta el sinistre. Protecció de dades: li informem que les dades seran tractades per ASITUR S.A., com Responsable del Tractament, amb NIF A28749976 i domicili social situat a Avinguda de los Encuartes, 21, 28760, Tres Cantos, Madrid. Finalitat: és la tramitació i gestió del sinistre per vostè comunicat. Base de legitimació: La base legal per al tractament de les seves dades és l'execució del contracte dassegurança subscrit amb la companyia asseguradora del vehicle. Categories de dades: Les categories de dades tractades seran les dades identificatives del prenedor i en el seu cas dels beneficiaris de la pòlissa. En el cas de facilitar la seva geolocalizació únicament serà  tractada aquesta informació a efectes de detectar la seva ubicació en el moment de sol·licitar el servei. Pot exercir els drets que se li confereixen tal i com es descriu a la informació addicional sobre protecció de dades a  https://www.asitur.es/aviso-legal/.",
      tlf_reale: '910920263',
      tlf_catalana: '932220212',
      tlf_PlusUltra: '917838383',
      tlf_SegurosBilbao: '946421241',
      tlf_Helvetia: '902120927',
      tlf_GES: '913308585',
      tlf_MGS: '902120863',
      tlf_UnionAlcoyana: '800888888',
      tlf_Asitur: '',
      title_screen_3: 'Avaria del vehicle',
      wheels: 'Roda/es punxada/es',
      batery: 'No tinc bateria',
      keys: 'Claus dintre del vehicle',
      fuel: 'No tinc combustible',
      other: 'Altres avaries',
      observations: 'Observacions',
      title_screen_4: "Indiqui'ns on està el vehicle",
      title_screen_4_1: 'Ubicació del vehicle',
      confirmed: 'Confirmar sol·licitud',
      noStart: 'No arrenca',
      ubi: 'Direcció',
      ubi_body: 'Lloc de recollida',
      highway: 'Carretera/Autovía',
      city: 'Centre urbà',
      parking: 'Vehicle en pàrquing subterrani',
      title_screen_5: 'INFORMACIÓ ADDICIONAL',
      title_screen_5_1: "DADES D'OCUPANTS",
      title_screen_5_2: 'INFORMACIÓ ADDICIONAL A LA SEVA ASSISTÈNCIA QUE CONSIDERI NECESSÀRIA',
      number_persons_ask: 'Quantes persones viatgen en el seu vehicle?',
      adults: 'Adults',
      boys: 'Nens',
      special_needs: 'Algú amb necessitats especials?',
      title_screen_6: 'RESUM DE SOL·LICITUD',
      type_fault: "Tipus d'avaria",
      number_persons: 'Nombre de persones',
      type_ubi: "Tipus d'avaria",
      parking_subway: 'Pàrquing subterrani',
      title_screen_7: "La seva petició d'assistència s'ha enregistrat correctament",
      end_text: 'Gràcies per utilitzar el servei.',
      rquired_checkbox: 'Ha de seleccionar una avaria',
      exp_number: ' El seu expedient és ',
      colab_name: ', Atendrà la seva sol·licitud ',
      alert_geo: 'La geolocalització introduïda és incorrecta. Si us plau revisi',
      attend_text: 'atendrà la seva sol·licitud',
      alta_no_ok: "En breu rebrà una trucada d'un dels nostres agents per finalitzar la seva sol·licitud.",
      grua_no_ok: 'seva grua va en camí.',
      tft_text: 'Pot posar-se en contacte amb nosaltres en aquest número de tèlefon',
    },
  },
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: 'es',
    resources,
    lng: 'es',

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
