import React from 'react'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Typography } from '@material-ui/core'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'
const useStyles = makeStyles({
  typography: {
    marginTop: '5%',
    fontWeight: 'bold',
    color: '#6E6E6E',
    fontSize: '40',
  },
  contariner: {
    padding: 20,
  },
  buttonGroup: {
    height: 55,
  },
})

export default function FormDialog(props) {
  const { t } = useTranslation()
  const classes = useStyles()
  return (
    <div>
      <Dialog open={props.open} onClose={props.handleCancel} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={12}>
              <Typography className={classes.typography} variant="h4">
                {t('title_cancel_formulary_card')}
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography variant="h6">{t('text_cancel_formulary_card')}</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.contariner}>
          <ButtonGroup color="primary" aria-label="outlined primary button group" fullWidth className={classes.buttonGroup}>
            <Button onClick={props.handleAcept} color="primary" variant="contained" fullWidth>
              {t('yes')}
            </Button>
            <Button onClick={props.handleCancel} color="primary" variant="contained" fullWidth>
              {t('no')}
            </Button>
          </ButtonGroup>
        </DialogActions>
      </Dialog>
    </div>
  )
}
